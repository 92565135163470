<template>
    <div class="filter mobile-popup" padding="low" type="add" :status="status">
        <div class="popup-header phone-only">
            <h1>Добавить претензию</h1>
            <div class="close" @click="$emit('hideMobilePopup')"></div>
        </div>
        <div class="inline mob-col" valign="bottom">
            <div class="filter-left">

                <div class="inline" valign="top" halign="start">
                    <div class="inline mob-col" valign="top">
                        <div class="column add-claim-type-block">
                            <div class="form-item">
                                <Select size="normal" :data.sync="types"></Select>
                                <label>Тип претензии *</label>
                            </div>

                            <div class="form-item" size="full" v-if="types.value == 4">
                                <input type="text" id="filterletter" class="text-input" v-model.trim="letterNumber" required />
                                <label for="filterletter">Номер письма *</label>
                            </div>

                            <div class="form-item">
                                <Select size="normal" :data.sync="action"></Select>
                                <label>Действие {{ !types.value || types.value == 3 ? '*' : ''}}</label>
                            </div>
                        </div>
                        <div class="column">                           
                            <div class="form-item pt-0" >
                                <div class="column">
                                    <div class="form-item " size="small">
                                        <input id="add_invoice_number" type="text" class="text-input" placeholder=" " :value="$store.state.common.create_claim_invoice.invoice" disabled />
                                        <label for="add_invoice_number">№ накладной *</label>
                                    </div>
                                    <span class="sub link _reverse" @click="$emit('openPopup', { type: 'SearchInvoice', claimType: types })">Найти накладную</span>
                                </div>
                                <div class="claims-new-attachments">
                                    <div v-for="(file, key) in files" :key="key" >
                                        <div class="attachment">
                                            <span class="attach-name link _reverse">{{file.name}}</span>
                                            <span class="btn-remove" title="Удалить" @click="removeAttachment(file)"></span>
                                        </div>
                                    </div>
                                    <div class="link-file-upload link _reverse" @click="addAttachments()">Прикрепить файлы</div>
                                    <input type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()"/>
                                </div>
                            </div>                         
                        </div>
                    </div>
                    <div class="inline form-item" :class="{_big: types.value == 4}">
                        <textarea id="filercom" v-model.trim="comment"></textarea>
                        <label for="filercom">Комментарий</label>
                    </div>
                </div>
                <div v-if="selectedOrder">
                    <strong v-if="products.status == 'loading'" class="loading">Загрузка продуктов по накладной</strong>
                    <div v-else>
                        <strong v-if="!products.list.length" class="no-items">Продукты не найдены *</strong>
                        <div class="wrap counted-list" v-else :class="{disabled: types.value == 3 || types.value == 6}">
                            <div class="checkbox counted" v-for="(item, key) in products.list" :key="key" :title="item.name">
                                <input :id="'claimAdd'+key" type="checkbox" v-model="item.checked" />
                                <label :for="'claimAdd'+key">
                                    <div>
                                        <span>[{{item.seriesNumber}}] {{ item.name }}</span>
                                        <input type="text" class="text-input" v-number-only v-model="item.count" />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column right bottom mobile-bottom">
                <div class="button" type="confirm" :class="{disabled: !allowToCreate}" @click="createClaim()">Добавить</div>
            </div>
        </div>
    </div>
</template>

<script>
import Select from '@/components/_Select.vue'
import * as _ from 'lodash';

export default {
    props: ['types'],
    components: {
        Select
    },
    data () {
        return {
            status:'',
            action: {
                open: false,
                value: '',
                text_value: null,
                list: [],
                placeholder: 'Действие'
            },
            products: {
                status: '',
                list: []
            },
            comment: '',
            letterNumber: '',
            files: []
        }
    },
    computed: {
        allowToCreate () {
            if (!this.types.value) {
                return false
            } else if (this.types.value == 1 || this.types.value == 2 || this.types.value == 5 || this.types.value == 7) { // возврат, недовложение, добавить серию
                return this.getItemsForCreate().length
            } else if (this.types.value == 3) { // излишки
                return this.action.value
            } else if (this.types.value == 4) { // возврат брака серии
                return this.letterNumber && this.getItemsForCreate().length
            } else if (this.types.value == 6) { // запросить комплект документов
                return true
            }
            return false;
        },
        selectedOrder () {
            return this.$store.state.common.create_claim_invoice.orderCode
        }
    },
    watch: {
        selectedOrder (newCount) {
            if (newCount != '') this.getClaimProducts()
        }
    },
    methods: {
        getClaimActions () {
            this.axios({url: 'api/v1/claim/getclaimextraactions', method: 'GET'})
                .then(resp => {
                    this.action.list = resp.data
                })
        },
        getClaimProducts () {
            this.products.status = 'loading'
            this.axios({url: 'api/v1/claim/getinvoiceproductcounts?orderCode=' + this.selectedOrder, method: 'GET'})
                .then(resp => {
                    this.products.status = ''
                    let list = []
                    for (let product of resp.data) {
                        list.push({
                            count: product.count,
                            name: product.productName,
                            code: product.productCode,
                            seriesNumber: product.seriesNumber?.trim(),
                            checked: false
                        })
                    }
                    this.products.list = list
                }).catch(() => {
                    this.products.status = ''
                })
        },
        getItemsForCreate () {
            let result = []
            for (let product of this.products.list) {
                if (product.checked) {
                    result.push({
                        productCode: product.code,
                        count: product.count,
                        seriesNumber: product.seriesNumber
                    })
                }
            }
            return result
        },
        clear () {
            this.types.value = ''
            this.types.text_value = ''
            this.action.value = ''
            this.action.text_value = ''
            this.comment = ''
            this.products.list = []
            this.files = [];
            this.$store.commit('common/CREATE_CLAIM_INVOICE')
        },
        createClaim () {
            this.status = 'loading'

            let formData = new FormData();

            _.each(this.files, file => {
                formData.append('file[]', file);
            });

            let data = {
                clientId: this.$store.state.common.client.id,
                orderCode: this.selectedOrder,
                claimType: this.types.value,
                comment: this.comment,
                numberOfInfoLetter: this.letterNumber,
                extraActionType: this.action.value,
                items: this.getItemsForCreate()
            }
            formData.append('claim_data', JSON.stringify(data));

            const headers = { 'Content-Type': 'multipart/form-data' };

            this.axios({url: '/api/v1/claim/createclaim', data: formData, method: 'POST', headers: headers})
                .then(resp => {
                    this.status = ''
                    this.clear()
                    this.$emit('openPopup', {
                        type: 'ClaimCreated',
                        id: resp.data.claimId
                    })
                    this.$store.commit('common/REQUEST_UPDATE_TABLE', true)
                }).catch(() => {
                    this.status = ''
                })
        },
        removeAttachment (file) {
            var index = this.files.indexOf(file);
            if (index !== -1) {
                this.files.splice(index, 1);
            }
        },
        addAttachments () {
            this.$refs.files.value = null;
            this.$refs.files.click();
        },
        handleFilesUpload () {
            const files = this.$refs.files.files;
            if (files && files.length) {
                for (var i = 0; i < files.length; i++) {
                    this.files.push(files[i]);
                }
            }
            this.$refs.files.value = null;
        }
    },
    mounted () {
        this.getClaimActions()
        if (this.selectedOrder) {
            this.getClaimProducts()
        }
    }
}
</script>

<style>
  input[type="file"]{
    display: none;
  }
</style>