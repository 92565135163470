<template>
    <div>
        <div class="switcher-group">
            <div class="button popup-switcher" type="search" @click="showMobilePopup()">Найти</div>
        </div>

        <div class="filter mobile-popup" :class="{_show: mobile_popup}">

            <div class="popup-header phone-only">
                <h1>Выбрать акции</h1>
                <div class="close" @click="hideMobilePopup()"></div>
            </div>

            <strong class="loading" v-if="manufactures.status == 'loading'">Загрузка списка производителей</strong>

            <div v-else>
                <div v-if="!manufactures.list.length">
                    <strong class="no-items">Производители не найдены</strong>
                </div>
                <div v-else>
                    <div class="form-item">
                        <input id="prname" type="text" class="text-input" v-model.trim="product_name" required />
                        <label for="prname">Наименование товара</label>
                    </div>
                    <div class="inline mob-col" valign="top">
                        <div class="wrap filter-left">
                            <div class="checkbox grid" size="1/3" v-for="(item, key) in manufacturesList" :key="key">
                                <input :id="'pmcb'+key" type="checkbox" v-model="item.checked" />
                                <label :for="'pmcb'+key">{{ item.name }}</label>
                            </div>
                            <div class="inline desktop-only" v-if="manufactures.list.length > manufactures.shortListCount">
                                <div class="button" type="more" :class="{less: manufactures.showFull}">
                                    <span v-if="!manufactures.showFull" @click="manufactures.showFull = true">Показать все</span>
                                    <span v-else @click="manufactures.showFull = false">Свернуть</span>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="12" height="3" x="0" y="4.5" rx="1.5" fill="#8AC148"/>
                                        <rect width="3" height="12" x="4.5" y="0" rx="1.5" fill="#8AC148"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="column right bottom mobile-bottom">
                            <div :class="{disabled: allOff}" class="button" type="decline" @click="resetFilter()">Сбросить</div>
                            <div :class="{disabled: products.status == 'loading'}" class="button" type="confirm" @click="getProductsAndReset()">Показать</div>
                            <div :class="{disabled: allOff}" class="button" type="download" @click="download()">
                                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.92055 13.0849C6.87781 13.1451 6.82947 13.2025 6.77553 13.2565C6.25574 13.7763 5.413 13.7763 4.89321 13.2565L0.389842 8.7531C-0.129947 8.23331 -0.129948 7.39056 0.389842 6.87078C0.909632 6.35099 1.75238 6.35099 2.27217 6.87078L4.54756 9.14617L4.54756 1.331C4.54756 0.59591 5.14347 0 5.87856 0C6.61366 0 7.20957 0.595911 7.20957 1.331L7.20957 9.03128L9.33598 6.90487C9.85577 6.38508 10.6985 6.38507 11.2183 6.90486C11.7381 7.42465 11.7381 8.2674 11.2183 8.78719L6.92055 13.0849Z" fill="#8AC148"/>
                                </svg>
                                Скачать таблицу в Excel</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table" v-if="manufactures.list.length">
            <strong class="loading" v-if="products.status == 'loading' && !products.list.length">Загрузка списка продуктов</strong>
            <div v-else>
                <div v-if="!products.list.length">
                    <strong class="no-items">Продукты не найдены</strong>
                </div>

                <div v-else>
                    <p class="products-notice">*Могут быть ограничения. Уточняйте информацию у менеджера отдела продаж</p>
                    <Table
                            ref="dataTable"
                            @getData="getTableData"
                            :data="products"
                            :schema="[
                            {id: 'productCode', name: 'Код товара'},
                            {id: 'productName', name: 'Наименование товара'},
                            {id: 'manufacturer', name: 'Производитель'},
                            {id: 'mechanics', name: 'Механика'},
                            {id: 'multiplicity', name: 'Кратность отпуска', align: 'center'}
                       ]">
                    </Table>
                </div>

            </div>
        </div>

        <div class="mobile-bottom phone-only">
        <div class="button" :class="{disabled: allOff}" type="download" @click="download()">
            <img src="@/assets/images/download.svg" />
            Скачать таблицу в Excel</div>
        </div>
    </div>
</template>

<script>
import Table from '@/components/Table.vue'
import FileSaver from 'file-saver'

export default {
    metaInfo: {
        title: 'Акции'
    },
    data () {
        return {
            status: '',
            product_name: '',
            manufactures: {
                status: '',
                list: [],
                shortListCount: 12,
                showFull: false
            },
            products: {
                status: '',
                list: [],
                count: null
            },
            mobile_popup: false
        }
    },
    components: {
        Table
    },
    computed: {
        allOff () {
            for (let item of this.manufactures.list) {
                if (item.checked) return false
            }
            return true
        },
        manufacturesList () {
            if (this.$store.state.common.isPhone) {
                return this.manufactures.list
            } else {
                let result = [], count = 0
                for (let item of this.manufactures.list) {
                    result.push(item)
                    count++
                    if (!this.manufactures.showFull && count >= this.manufactures.shortListCount) break
                }
                return result
            }
        }
    },
    methods: {
        getProductsAndReset () {
          this.getProducts()
          if (this.$refs.dataTable) this.$refs.dataTable.resetPaginator()
        },
        download () {
            let data = {
                productName: this.product_name ? this.product_name : null,
                manufacturers: this.getSelectedManufactures()
            }
            this.axios({url: 'api/v1/actions/getreport', data: data, method: 'POST', responseType: 'blob'})
                .then(resp => {
                    const filename = 'promotions_report.xlsx'
                    const blob = new Blob([resp.data], {
                        type: 'application/vnd. ms-excel'
                    })
                    FileSaver.saveAs(blob, filename)
                })
        },
        getTableData (e) {
            this.getProducts(e.paginator, e.sort)
        },
        showMobilePopup () {
            this.mobile_popup = true
            document.documentElement.style.overflow = 'hidden'
        },
        hideMobilePopup () {
            this.mobile_popup = false
            document.documentElement.style.overflow = 'auto'
        },
        resetFilter () {
            this.product_name = ''
            for (let item of this.manufactures.list) {
                item.checked = false
            }
        },
        getSelectedManufactures () {
            let result = []
            for (let item of this.manufactures.list) {
                if (item.checked) {
                    result.push(item.name)
                }
            }
            if (result.length) return result
            return null
        },
        getManufactures () {
            this.manufactures.status = 'loading'
            return this.axios({url: 'api/v1/actions/getmanufacturers', method: 'GET'})
                .then(resp => {
                    this.manufactures.status = ''

                    let manufactures = []
                    for (let manufacture of resp.data) {
                        let item = {
                            name: manufacture,
                            checked: true
                        }
                        manufactures.push(item)
                    }
                    this.manufactures.list = manufactures

                }).catch(() => {
                    this.manufactures.status = ''
                })
        },
        getProducts (paginator = false, sort = false) {
            let sorting = sort ? sort : (this.$refs.dataTable && this.$refs.dataTable.sort ? this.$refs.dataTable.sort : false)
            this.products.status = 'loading'
            let data = {
                page: paginator ? paginator.current_page : 1,
                itemsPerPage: paginator ? paginator.per_page.value : 10,
                sortBy: sorting ? sorting.name : null,
                sortDesc: sorting ? (sorting.direction == 'DESC' ? true : false) : null,
                productName: this.product_name ? this.product_name : null,
                manufacturers: this.getSelectedManufactures()
            }
            this.axios({url: 'api/v1/actions/getall', data: data, method: 'POST'})
                .then(resp => {
                    this.products.status = ''
                    this.products.list = resp.data.data
                    this.products.count = resp.data.count
                }).catch(() => {
                    this.products.status = ''
                })
        }
    },
    async created () {
        await this.getManufactures()
        if (this.manufactures.list.length) {
            this.getProducts()
        }
    }
}
</script>

<style lang="scss">
    @import "./../assets/styles/views/promotions.scss";
</style>