import axios from 'axios'

const state = {
    client: {
        name: '',
        isOrganisation: true,
        id: false
    },
    isPhone: false,
    create_claim_invoice: {},
    requestUpdateTable: false,
    managers: {
        list: [],
        types: []
    },
    authType: 'token',
    userData: {},
    maxFileSizeMB: 30
}
const getters = {
    personalManager (state) {
        if (state.managers.list.length) {
            for (let manager of state.managers.list) {
                if (manager.type == 'PersonalManager') {
                    return {
                        name: manager.firstName + ' ' + manager.lastName,
                        phone: manager.phone
                    }
                }
            }
        }
        return false
    },
    isLegalEntity (state) {
        return state.userData.isLegalEntity || false;
    }
}

const actions = {
    loadUserData({ commit }) {
        axios({url: 'api/v1/user/getUserData', method: 'GET' })
            .then(resp => {
                commit('SET_USER_DATA', resp.data)
            })
            .catch(err => {
                if (err.response && err.response.data.Message) {
                    const msg = '[' + err.response.status + ' ' + err.response.statusText + ']  ' + err.response.data.Message  
                    console.error(msg);
                }
            })
    }
}

const mutations = {
    SET_AUTH_TYPE (state, data) {
        state.authType = data
    },
    SET_IS_PHONE (state, data) {
        state.isPhone = data
    },
    SET_CLIENT (state, data) {
        state.client = data
    },
    CREATE_CLAIM_INVOICE (state, data = {orderCode:'', invoice: ''}) {
        state.create_claim_invoice = {
            orderCode: data.orderCode,
            invoice: data.invoice
        }
    },
    REQUEST_UPDATE_TABLE (state, data) {
        state.requestUpdateTable = data
    },
    SET_MANAGERS (state, data) {
        state.managers.list = data
    },
    SET_MANAGER_TYPES (state, data) {
        state.managers.types = data
    },
    SET_USER_DATA (state, data) {
        state.userData = data                       
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}