<template>
    <div class="wrap cards">
        <router-link to="/quality" class="card">
            <div class="icon"><img type="quality" src="@/assets/images/cards/quality.svg" /></div>
            <div class="text">
                <strong>Документы</strong>
                <p>Сертификаты, паспорта, удостоверения</p>
                <p class="link" @click.prevent="$router.push('/quality?tab=qualityDocPkgs')">Пакеты документов</p>
                <p class="link" @click.prevent="$router.push('/quality?tab=quality')">Документы качества</p>
            </div>
        </router-link>
        <router-link to="/claims" class="card">
            <div class="icon"><img type="claims" src="@/assets/images/cards/claims.svg" /></div>
            <div class="text">
                <strong>Претензии</strong>
                <p class="link" @click.prevent="$router.push('/claims')">По количеству, качеству, браку</p>
                <p class="link" @click.prevent="$emit('openPopup', 'ServiceClaim')">Претензии к{{'\xa0'}}сервису</p>
                <p class="link" @click.prevent="$router.push('/claims?tab=question')">Письмо претензионисту</p>
            </div>
        </router-link>
        <div @click="$emit('openPopup', 'RequestInvoice')" class="card">
            <div class="icon"><img type="enote" src="@/assets/images/cards/enote.svg" /></div>
            <div class="text">
                <strong>Электронная накладная</strong>
                <p>Запрос на повторную отправку электронной накладной</p>
            </div>
        </div>

        <router-link to="/receivables" class="card" v-if="isLegalEntity">
            <div class="icon"><img type="debt" src="@/assets/images/cards/debt.svg" /></div>
            <div class="text">
                <strong>Дебиторская задолженность</strong>
                <p>Реквизиты, запрос акта сверки, графика платежей</p>
            </div>
        </router-link>
        <router-link to="/boomerang" class="card" v-if="isLegalEntity">
            <div class="icon"><img type="boomerang" src="@/assets/images/cards/boomerang.svg" /></div>
            <div class="text">
                <strong>Программа &laquo;Бумеранг&raquo;</strong>
                <p>Товары с дополнительной кредит-нотой</p>
            </div>
        </router-link>
        <router-link to="/promotions" class="card">
            <div class="icon"><img type="promotions" src="@/assets/images/cards/promotions.svg" /></div>
            <div class="text">
                <strong>Акции</strong>
                <p>Список актуальных акций</p>
            </div>
        </router-link>

        <div @click="$emit('openPopup', 'RequestPriceList')" class="card">
            <div class="icon"><img type="price" src="@/assets/images/cards/price.svg" /></div>
            <div class="text price">
                <strong>Прайс-лист</strong>
                <p class="link">Запросить прайс</p>
                <p>Скачать новые строки прайс-листа в{{'\xa0'}}<span class="link" @click.prevent.stop="$emit('newLinesDownload', 'pdf')">PDF</span> или <span class="link" @click.prevent.stop="$emit('newLinesDownload', 'excel')">Excel</span></p>
            </div>
        </div>
        <div @click="$emit('openPopup', 'Support')" class="card">
            <div class="icon"><img type="support" src="@/assets/images/cards/support.svg" /></div>
            <div class="text">
                <strong>Техническая поддержка</strong>
                <p>При возникновении проблем в{{'\xa0'}}работе сервиса, обращайтесь в{{'\xa0'}}службу поддержки</p>
            </div>
        </div>
        <div @click="$emit('openPopup', 'Managers')" class="card">
            <div class="icon"><img type="managers" src="@/assets/images/cards/managers.svg" /></div>
            <div class="text">
                <strong>Контакты менеджеров</strong>
                <p>Написать, позвонить своему менеджеру</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['routeName'],
    metaInfo: {
        title: 'Главная'
    },
    computed: {
        isLegalEntity () {
            return this.$store.state.common.userData.isLegalEntity
        }
    }
}
</script>

<style lang="scss">
    @import "./../assets/styles/views/main.scss";
</style>