<template>
    <div ref="scrollArea" class="smooth-scrollbar">
        <slot/>
    </div>
</template>

<script>
import SmoothScrollbar, { ScrollbarPlugin } from 'smooth-scrollbar'

class ModalPlugin extends ScrollbarPlugin {
    static pluginName = 'modal';

    static defaultOptions = {
        open: false,
    };

    transformDelta(delta) {
        return this.options.open ? { x: 0, y: 0 } : delta;
    }
}

SmoothScrollbar.use(ModalPlugin);

export default {
    name: 'smooth-scrollbar',
    props: {
        globalOptions: {
            type: Object,
            required: false,
            default: () => ({})
        },
        options: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            scrollbar: null,
            defaultOptions: {
                damping: 0.1,
                thumbMinSize: 20,
                renderByPixels: true,
                alwaysShowTracks: false,
                continuousScrolling: true,
                delegateTo: null,
                plugins: {},
                syncCallbacks: true,
            },
            pos: {
                top: 0,
                left: 0
            }
        }
    },
    methods: {
        openModal () {
            this.scrollbar.updatePluginOptions('modal', { open: true })
        },
        closeModal () {
            this.scrollbar.updatePluginOptions('modal', { open: false })
        },
        getPosition () {
            return {
                top: this.pos.top + 'px',
                left: this.pos.left + 'px'
            }
        }
    },
    mounted () {
        this.scrollbar = SmoothScrollbar.init(
            this.$refs.scrollArea,
            Object.assign({}, this.defaultOptions, this.globalOptions, this.options)
        )

        this.scrollbar.addListener(({ offset }) => {
            this.pos.top = offset.y;
            this.pos.left = offset.x;
        });
    },
    destroyed () {
        this.scrollbar.destroy()
        this.scrollbar = null
    }
}
</script>

<style scoped>
    .smooth-scrollbar {
        width: 100%;
        height: 100%;
    }
</style>
