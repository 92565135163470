import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import Promotions from '../views/Promotions.vue'
import Claims from '../views/Claims.vue'
import Receivables from '../views/Receivables.vue'
import Boomerang from '../views/Boomerang.vue'
import Quality from '../views/Quality.vue'

import PageNotFound from '../views/PageNotFound.vue'

import store from './../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'main',
        meta: {
            requiresAuth: true
        },
        component: Main
    },
    {
        path: '/promotions',
        name: 'promotions',
        meta: {
            requiresAuth: true
        },
        component: Promotions
    },
    {
        path: '/claims',
        name: 'claims',
        meta: {
            requiresAuth: true
        },
        component: Claims
    },
    {
        path: '/receivables',
        name: 'receivables',
        meta: {
            requiresAuth: true
        },
        component: Receivables
    },
    {
        path: '/boomerang',
        name: 'boomerang',
        meta: {
            requiresAuth: true
        },
        component: Boomerang
    },
    {
        path: '/quality',
        name: 'quality',
        meta: {
            requiresAuth: true
        },
        component: Quality
    },
    {
        path: "*",
        name: 'notFound',
        component: PageNotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isLogged = store.getters['auth/isLoggedIn'];

    if(requiresAuth && !isLogged) {
        next('/login')

    } else if (to.name == 'login' && isLogged) {
        next('/')
    } else if ((to.name == 'receivables' || to.name == 'boomerang') && !store.getters['common/isLegalEntity']){
        next('/')
    } else {
        next()
    }
})

export default router
