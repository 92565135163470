<template>
    <div class="popup mobile-popup" type="MessageSend" size="small">
        <div class="popup-header" v-if="activePopup.header">
            <h1>{{ activePopup.header }}</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <p v-if="activePopup.message">{{ activePopup.message }}</p>
            </div>

            <div class="mobile-bottom inline">
                <div class="button" type="decline" @click="$emit('closePopup')">Окей</div>
            </div>
        </Component>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

    export default {
        props: ['activePopup', 'contentScroll'],
        components: {
            SmoothScrollbar
        }
    }
</script>


<style lang="scss">
    @import "../../assets/styles/components/popup_MessageSend";
</style>