<template>
    <div class="select" :class="{_open: data.open, _filled: (data.value != '' && data.value != null) || data.text_value != '' || data.always_filled, disabled: isDisabled }">
        <div v-if="$store.state.common.isPhone">
            <div v-if="isObjectList">
                <select class="select" :class="{disabled: isDisabled}" v-model="data.value" :disabled="isDisabled" @change="selectNative()">
                    <option @click="select(item)" v-for="(item, key) in data.list" :key="key" :value="item.id">{{ item.value }}</option>
                </select>
            </div>
            <div v-else>
                <select class="select" :class="{disabled: isDisabled}" v-model="data.value" :disabled="isDisabled" @change="selectNative()">
                    <option @click="select(item)" v-for="(item, key) in data.list" :key="key" :value="item">{{ item }}</option>
                </select>
            </div>
        </div>

        <div v-else v-click-outside="hideList" @click="$emit('hideSelects')">
            <div class="placeholder" :disabled="isDisabled"  @click="toggleList()" :class="{no_value: !data.value && !data.text_value, disabled: isDisabled}">
                {{ value }}
            </div>
            <div class="list" >
                <!-- <SmoothScrollbar ref="scrollbar" :globalOptions="scrollOptions"> -->
                    <div v-if="isObjectList">
                        <div :title="item.value" class="list-item" @click="select(item)" :class="{active: data.value == item.id}" v-for="(item, key) in data.list" :key="key">{{ item.value }}</div>
                    </div>
                    <div v-else>
                        <div :title="item" class="list-item" @click="select(item)" :class="{active: data.value == item}" v-for="(item, key) in data.list" :key="key">{{ item }}</div>
                    </div>
                <!-- </SmoothScrollbar> -->
            </div>
        </div>
    </div>
</template>

<script>
    // import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

    export default {
        props: ['data'],
        data () {
          return {
              scrollOptions: {
                  alwaysShowTracks:true
              }
          }
        },
        components: {
            // SmoothScrollbar
        },
        computed: {
            isObjectList () {
              if (typeof this.data.list[0] == 'object') return true
              return false
            },
            value () {
                if (this.data.text_value) return this.data.text_value
                if (this.data.value) return this.data.value
                return '\xa0'
            },
            isOpen () {
                return this.data.open
            },
            isDisabled(){
                return !this.data.list || this.data.list.length == 0;
            }
        },
        watch: {
          isOpen () {
              this.$nextTick(() => {
                  this.$refs.scrollbar.scrollbar.update()
              })
          }
        },
        methods: {
            getTextById (id) {
                for (let item of this.data.list) {
                    if (item.id == id) {
                        return item.value
                    }
                }
            },
            selectNative () {
                this.hideList()
                this.$emit('select')
            },
            select (item) {
                if (this.isObjectList) {
                    this.data.text_value = item.value
                    this.data.value = item.id
                } else {
                    this.data.value = item
                }

                this.hideList()
                this.$emit('select')
            },
            hideList () {
                this.data.open = false
            },
            toggleList () {
                this.data.open = !this.data.open
            }
        }
    }
</script>