<template>
    <div class="popup mobile-popup" type="Claim" size="big">
        <div class="popup-header inline" valign="top">
            <div class="column">
                <h1><span>№ претензии:</span> {{ this.activePopup.id }}</h1>
            </div>
            <div class="wrap" v-if="($store.state.common.isPhone && details.statusId != 'CancelledByClient') || !$store.state.common.isPhone">
                <div class="button free2" v-if="details.statusId != 'CancelledByClient'" @click="cancelClaim()" type="decline">Отменить претензию</div>
                <div class="inline">
                    <div v-if="details.torg2Visible" class="button" @click="getTorg2()" type="download"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.92055 13.0849C6.87781 13.1451 6.82947 13.2025 6.77553 13.2565C6.25574 13.7763 5.413 13.7763 4.89321 13.2565L0.389842 8.7531C-0.129947 8.23331 -0.129948 7.39056 0.389842 6.87078C0.909632 6.35099 1.75238 6.35099 2.27217 6.87078L4.54756 9.14617L4.54756 1.331C4.54756 0.59591 5.14347 0 5.87856 0C6.61366 0 7.20957 0.595911 7.20957 1.331L7.20957 9.03128L9.33598 6.90487C9.85577 6.38508 10.6985 6.38507 11.2183 6.90486C11.7381 7.42465 11.7381 8.2674 11.2183 8.78719L6.92055 13.0849Z" fill="#8AC148"></path></svg>Скачать Торг-2</div>
                    <div v-if="details.torg12Visible" class="button" @click="getTorg12()" type="download"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.92055 13.0849C6.87781 13.1451 6.82947 13.2025 6.77553 13.2565C6.25574 13.7763 5.413 13.7763 4.89321 13.2565L0.389842 8.7531C-0.129947 8.23331 -0.129948 7.39056 0.389842 6.87078C0.909632 6.35099 1.75238 6.35099 2.27217 6.87078L4.54756 9.14617L4.54756 1.331C4.54756 0.59591 5.14347 0 5.87856 0C6.61366 0 7.20957 0.595911 7.20957 1.331L7.20957 9.03128L9.33598 6.90487C9.85577 6.38508 10.6985 6.38507 11.2183 6.90486C11.7381 7.42465 11.7381 8.2674 11.2183 8.78719L6.92055 13.0849Z" fill="#8AC148"></path></svg>Скачать Торг-12</div>
                </div>
            </div>

            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <div class="loading" v-if="status == 'loading'">Загрузка претензии</div>

                <div class="inline mob-col" v-else>
                    <div class="column">
                        <div class="claim-text">
                            <span>№ накладной:</span>
                            <strong> {{ details.invoiceNumber }}</strong>
                        </div>
                        <div class="claim-text">
                            <span>Тип претензии:</span>
                            <strong> {{ details.typeName }}</strong>
                        </div>
                        <div class="claim-text">
                            <span>Дата регистрации:</span>
                            <strong> {{ details.claimDate | formatDate }}</strong>
                        </div>
                        <div class="claim-text">
                            <span>Статус претензии:</span>
                            <strong> {{ details.statusName }}</strong>
                        </div>
                        <div class="claim-text">
                            <span>Решение:</span>
                            <strong> {{ details.verdictName }}</strong>
                        </div>
                        <div class="claim-text claim-attachments" v-if="details.files.length>0">
                            <div>Файлы:</div>
                            <div class="attachments">
                                <div v-for="(file, key) in details.files" :key="key" >
                                    <span class="link _reverse"
                                        v-if="filesExpanded || key<filesVisibleCount"
                                        @click="downloadFile(file)">{{file.name}}
                                    </span>
                                </div>
                                <div class="attach-more link _reverse" 
                                    v-if="details.files.length>4 && !filesExpanded" 
                                    @click="filesExpanded=true">еще {{details.files.length-filesVisibleCount}} файлов
                                </div>
                                <div class="attach-more link _reverse" v-if="filesExpanded" @click="filesExpanded=false">Свернуть</div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap">
                        <div class="claim-grid claim-text">
                            <div><span>Текст претензии</span></div>
                            <strong>{{ details.clientComment }}</strong>
                        </div>
                        <div class="claim-grid claim-text">
                            <div><span>Комментарий к решению:</span></div>
                            <strong>{{ details.verdictComment }}</strong>
                        </div>
                    </div>
                </div>

                <div class="table" v-if="status != 'loading'">
                    <div>
                        <Table v-if="products.list.length"
                               ref="dataTable"
                               @getData="getTableData"
                               perPageAlign="top"
                               :data="products"
                               :schema="[
                                    {id: 'productCode', name: 'Код товара'},
                                    {id: 'productName', name: 'Наименование'},
                                    {id: 'manufacturer', name: 'Производитель'},
                                    {id: 'seriesNumber', name: 'Серия'},
                                    {id: 'quantity', name: 'Кол-во'},
                                    {id: 'price', name: 'Цена'},
                                    {id: 'sumWithVat', name: 'Сумма с\xa0НДС', align: 'right'}
                               ]">
                        </Table>
                    </div>
                </div>
            </div>
        </Component>

        <div class="phone-only column">
            <div v-if="details.torg2Visible" class="button" type="download" @click="getTorg2()">
                <img src="@/assets/images/download.svg" />
                Скачать Торг-2</div>
            <div v-if="details.torg12Visible" class="button" type="download" @click="getTorg12()">
                <img src="@/assets/images/download.svg" />
                Скачать Торг-12</div>
        </div>

    </div>
</template>

<script>
import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'
import Table from '@/components/Table.vue'
import FileSaver from 'file-saver'

export default {
    props: ['activePopup', 'contentScroll'],
    data () {
        return {
            status: '',
            products: {
                status: '',
                list: [],
                count: null
            },
            filesVisibleCount: 4,
            filesExpanded: false,
            details: {}
        }
    },
    components: {
        SmoothScrollbar,
        Table
    },
    methods: {
        cancelClaim () {
            this.axios({url: '/api/v1/claim/cancelclaim?claimId=' + this.activePopup.guId, method: 'GET'})
                .then(() => {
                    this.$emit('closePopup')
                    this.$store.commit('common/REQUEST_UPDATE_TABLE', true)
                })
        },
        getTorg2 () {
            this.axios({url: 'api/v1/claim/gettorg2pdf?claimId=' + this.activePopup.guId, method: 'GET', responseType: 'blob'})
                .then(resp => {
                    const filename = 'torg2.pdf'

                    const blob = new Blob([resp.data], {
                        type: 'application/pdf'
                    })
                    FileSaver.saveAs(blob, filename)
                })
        },
        getTorg12 () {
            this.axios({url: 'api/v1/claim/gettorg12pdf?claimId=' + this.activePopup.guId, method: 'GET', responseType: 'blob'})
                .then(resp => {
                    const filename = 'torg12.pdf'

                    const blob = new Blob([resp.data], {
                        type: 'application/pdf'
                    })
                    FileSaver.saveAs(blob, filename)
                })
        },
        getTableData (e) {
            this.getClaimProducts(e.paginator, e.sort)
        },
        getClaimDetails () {
            return new Promise((resolve) => {
                this.axios({url: 'api/v1/claim/getclaim?claimId=' + this.activePopup.guId, method: 'GET'})
                    .then(resp => {
                        this.details = resp.data
                        resolve()
                    })
            })
        },
        getClaimProducts (paginator = false, sort = false) {
            return new Promise((resolve) => {
                this.products.status = 'loading'
                let data = {
                    claimId: this.activePopup.guId,
                    page: paginator ? paginator.current_page : 1,
                    itemsPerPage: paginator ? paginator.per_page.value : 10,
                    sortBy: sort ? sort.name : null,
                    sortDesc: sort ? (sort.direction == 'DESC' ? true : false) : null
                }
                this.axios({url: 'api/v1/claim/getclaimparts', data: data, method: 'POST'})
                    .then(resp => {
                        this.products.status = ''
                        this.products.list = resp.data.data
                        this.products.count = resp.data.count
                        resolve()
                    }).catch(() => {
                        this.products.status = ''
                        resolve()
                    })
            })
        },
        downloadFile (file) {
            this.axios({url: 'api/v1/Files/GetFile?fileId=' + file.fileId, method: 'GET', responseType: 'blob'})
                .then(resp => {
                    const blob = new Blob([resp.data], {
                        type: file.contentType
                    })
                    FileSaver.saveAs(blob, file.name)
                })
        }
    },
    async mounted () {
        this.status = 'loading'

        await Promise.all([
            this.getClaimDetails(),
            this.getClaimProducts()
        ]).then(
            this.status = ''
        )
    }
}
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_Claim.scss";
</style>