<template>
    <div class="filter mobile-popup" padding="low" type="question" :status="status">
        <div class="popup-header phone-only">
            <h1>Задать вопрос</h1>
            <div class="close" @click="$emit('hideMobilePopup')"></div>
        </div>
        <div class="inline mob-col" valign="bottom">
            <div class="filter-left">
                <div class="inline" valign="top" halign="start">
                    <div class="inline mob-col" valign="top">
                        <div class="column">                           
                            <div class="form-item pt-0" >
                                <div class="column">
                                    <div class="form-item " size="small">
                                        <input id="add_invoice_number" type="text" class="text-input" placeholder=" " :value="claim_invoice.invoice" disabled />
                                        <label for="add_invoice_number">№ накладной *</label>
                                    </div>
                                    <span class="sub link _reverse" @click="$emit('openPopup', getSearchInvoicePopup())">Найти накладную</span>
                                </div>
                                <div class="claims-new-attachments">
                                    <div v-for="(file, key) in files" :key="key" >
                                        <div class="attachment">
                                            <span class="attach-name link _reverse">{{file.name}}</span>
                                            <span class="btn-remove" title="Удалить" @click="removeAttachment(file)"></span>
                                        </div>
                                    </div>
                                    <div class="link-file-upload link _reverse" @click="addAttachments()">Прикрепить файлы</div>
                                    <input type="file" id="files" ref="files" multiple v-on:change="handleFilesUpload()"/>
                                </div>
                            </div>                         
                        </div>
                        <div class="column">
                            <div class="form-item">
                                <Select size="normal" :data.sync="warehouses"></Select>
                                <label>Склад погрузки *</label>
                            </div>
                        </div>
                    </div>
                    <div class="inline form-item">
                        <textarea id="filercom" v-model.trim="comment"></textarea>
                        <label for="filercom">Комментарий *</label>
                    </div>
                </div>
            </div>
            <div class="column right bottom mobile-bottom">
                <div class="button" type="confirm" :class="{disabled: !allowToCreate}" @click="sendQuestion()">Отправить</div>
            </div>
        </div>
    </div>
</template>

<script>
import Select from '@/components/_Select.vue'
import * as _ from 'lodash';

export default {
    components: {
        Select
    },
    data () {
        return {
            status:'',
            claim_invoice: {},
            warehouses: {
                open: false,
                list: [],
                value: null,
                text_value: '',
                placeholder: 'Склад погрузки',
                always_filled: true
            },
            comment: '',
            files: [],
        }
    },
    computed: {
        allowToCreate () {
            if (!this.warehouses.text_value) {
                return false
            } else if (this.warehouses.text_value && this.comment && this.selectedInvoice) {
                return true
            }
            return false;
        },
        selectedInvoice () {
            return this.claim_invoice.invoice
        }
    },
    methods: {
        getClaimWarehouses () {
            this.axios({url: 'api/v1/claim/getclaimwarehouses?clientId=' + this.$store.state.common.client.id, method: 'GET'})
                .then(resp => {
                    this.warehouses.list = resp.data
                })
        },
        getSearchInvoicePopup() {
            return { 
                type: 'SearchInvoice',
                useCallback: true, 
                callback: this.searchInvoicePopupCallback
            }
        },
        searchInvoicePopupCallback (data) {
            this.claim_invoice = data
        },
        clear () {
            this.claim_invoice = {}
            this.warehouses.value = ''
            this.warehouses.text_value = ''
            this.comment = ''
            this.files = []
        },
        sendQuestion () {
            this.status = 'loading'

            let formData = new FormData();

            let size = 0;
            _.each(this.files, file => {
                formData.append('file[]', file);
                size += file.size;
            });

            const maxSize = this.$store.state.common.maxFileSizeMB;
            if (size > maxSize * 1024 * 1024) {
                this.status = ''
                this.$emit('openPopup', {
                    type: 'MessageBox',
                    header: 'Вопрос не отправлен',
                    message: 'Максимальный размер прикрепляемых файлов не должен превышать ' + maxSize + ' Мб'
                })
                return;
            }

            let data = {
                clientId: this.$store.state.common.client.id,
                invoiceNum: this.selectedInvoice,
                warehouseId: this.warehouses.value,
                comment: this.comment
            }
            formData.append('question_data', JSON.stringify(data));
            
            const headers = { 'Content-Type': 'multipart/form-data' };

            this.axios({url: '/api/v1/claim/sendQuestion', data: formData, method: 'POST', headers: headers})
                .then(() => {
                    this.status = ''
                    this.clear()
                    this.$emit('openPopup', {
                        type: 'MessageBox',
                        header: 'Письмо претензионисту отправлено',
                        message: 'Наш специалист свяжется с вами в ближайшее время.'
                    })
                }).catch(err => {
                    this.status = ''
                    const msg = err.response?.data?.message
                                ?? err.response?.data?.Message
                                ?? 'Не удалось отправить вопрос претензионисту. Попробуйте повторить попытку позже.'
                    this.$emit('openPopup', {
                        type: 'MessageBox',
                        header: 'Вопрос не отправлен',
                        message: msg
                    })
                })
        },
        removeAttachment (file) {
            var index = this.files.indexOf(file);
            if (index !== -1) {
                this.files.splice(index, 1);
            }
        },
        addAttachments () {
            this.$refs.files.click();
        },
        handleFilesUpload () {
            const files = this.$refs.files.files;
            if (files) {
                for (var i = 0; i < files.length; i++) {
                    this.files.push(files[i]);
                }
            }
        }
    },
    mounted () {
        this.getClaimWarehouses()
    },
    unmount () {
        this.clear();
    }
}
</script>

<style>
  input[type="file"]{
    display: none;
  }
</style>