<template>
    <div class="header">
        <div class="wrapper">
            <div class="inline">
                <div class="burger" @click="openMobileMenu()">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <router-link to="/" class="logo">
                    <img class="desktop-only" src="@/assets/images/logo.png" />
                    <img class="phone-only" src="@/assets/images/logo-mobile.png" />
                </router-link>

                <div>
                    <div class="inline" valign="center" halign="between">
                        <div class="network inline" valign="center">
                            <div class="network-address" v-if="$store.state.common.client.name" :title="$store.state.common.client.name">{{ $store.state.common.client.name }}</div>
                            <div class="button" type="dark" @click="$emit('openPopup', 'SelectPoint')">Выбрать аптеку</div>
                            <div class="desktop-only header-link">
                                <nobr>
                                    <img src="@/assets/images/faq.svg" />
                                    <a @click="faqDownload()">Инструкция</a>
                                </nobr>
                            </div>
                        </div>

                        <!--<div class="managers">Контакты менеджеров
                            <svg width="33" height="19" viewBox="0 0 33 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0)">
                                    <path d="M21.3003 5.10021C21.4003 7.80021 19.1003 10.1002 16.3003 10.2002C13.5003 10.2002 11.1003 8.10021 11.2003 5.00021C11.2003 2.50021 13.2003 0.100212 16.2003 0.000211783C19.0003 -0.0997882 21.3003 2.20021 21.3003 5.10021ZM19.6003 5.10021C19.6003 3.20021 18.1003 1.70021 16.2003 1.70021C14.3003 1.70021 12.8003 3.30021 12.8003 5.10021C12.8003 7.00021 14.3003 8.50021 16.2003 8.50021C18.1003 8.50021 19.7003 6.90021 19.6003 5.10021Z" fill="#7789A0"/>
                                    <path d="M9.40039 18.1998C9.50039 16.8998 9.20039 15.5998 9.70039 14.2998C10.5004 12.1998 12.4004 10.8998 14.6004 10.7998C15.7004 10.7998 16.8004 10.7998 17.9004 10.7998C20.5004 10.8998 22.7004 12.7998 23.0004 15.2998C23.1004 16.1998 23.1004 16.9998 23.1004 17.8998C23.1004 17.9998 23.0004 18.0998 22.9004 18.0998C22.5004 18.0998 22.0004 18.0998 21.6004 18.0998C21.4004 18.0998 21.4004 17.9998 21.4004 17.7998C21.4004 17.1998 21.4004 16.6998 21.4004 16.0998C21.4004 14.9998 21.0004 13.9998 20.2004 13.2998C19.5004 12.6998 18.8004 12.3998 17.9004 12.3998C16.7004 12.3998 15.5004 12.3998 14.3004 12.3998C12.5004 12.4998 11.1004 13.9998 11.0004 15.7998C11.0004 16.4998 11.0004 17.0998 11.0004 17.7998C11.0004 18.0998 10.9004 18.0998 10.7004 18.0998C10.3004 18.1998 9.90039 18.1998 9.40039 18.1998Z" fill="#7789A0"/>
                                    <path d="M2.2998 7.7998C2.2998 5.5998 4.0998 3.7998 6.2998 3.7998C8.4998 3.7998 10.2998 5.59981 10.2998 7.89981C10.2998 10.0998 8.4998 11.8998 6.2998 11.8998C4.0998 11.8998 2.2998 10.0998 2.2998 7.7998ZM3.9998 7.89981C3.9998 9.19981 4.9998 10.2998 6.39981 10.2998C7.69981 10.2998 8.6998 9.1998 8.6998 7.9998C8.6998 6.6998 7.5998 5.5998 6.2998 5.5998C4.9998 5.4998 3.9998 6.59981 3.9998 7.89981Z" fill="#7789A0"/>
                                    <path d="M26.2001 3.7998C28.4001 3.7998 30.2001 5.59981 30.2001 7.89981C30.2001 10.0998 28.4001 11.8998 26.2001 11.8998C24.0001 11.8998 22.2001 10.0998 22.2001 7.7998C22.1001 5.5998 24.0001 3.7998 26.2001 3.7998ZM28.5001 7.89981C28.5001 6.59981 27.4001 5.4998 26.1001 5.4998C24.8001 5.4998 23.8001 6.5998 23.8001 7.7998C23.8001 9.0998 24.9001 10.1998 26.2001 10.1998C27.5001 10.1998 28.5001 9.0998 28.5001 7.89981Z" fill="#7789A0"/>
                                    <path d="M1.69961 18.2C1.09961 18.2 0.599609 18.2 -0.000390626 18.2C-0.100391 17.2 0.0996094 16.3 0.599609 15.5C1.59961 13.9 2.99961 13 4.89961 13C5.89961 13 6.89961 13 7.89961 13C7.99961 13 8.19961 13 8.29961 13C8.79961 13.1 9.09961 13.5 8.99961 14C8.99961 14.4 8.59961 14.7 8.09961 14.7C6.99961 14.7 5.89961 14.7 4.79961 14.7C3.99961 14.7 3.19961 15 2.59961 15.6C1.99961 16.2 1.69961 17 1.69961 17.8C1.69961 17.9 1.69961 18 1.69961 18.2Z" fill="#7789A0"/>
                                    <path d="M32.5002 18.2001C31.9002 18.2001 31.4002 18.2001 30.8002 18.2001C30.8002 17.9001 30.8002 17.7001 30.7002 17.4001C30.4002 15.8001 29.2002 14.7001 27.6002 14.7001C26.6002 14.7001 25.5002 14.7001 24.5002 14.7001C24.0002 14.7001 23.7002 14.4001 23.6002 14.0001C23.5002 13.5001 23.8002 13.2001 24.3002 13.1001C24.4002 13.1001 24.6002 13.1001 24.7002 13.1001C25.7002 13.1001 26.6002 13.1001 27.6002 13.1001C30.3002 13.1001 32.6002 15.4001 32.5002 18.1001C32.5002 18.1001 32.5002 18.1001 32.5002 18.2001Z" fill="#7789A0"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="32.5" height="18.2" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>-->
                        <div class="logout" @click.stop.prevent="$store.dispatch('auth/logout')">
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.52352 2.6699C2.52352 9.2233 2.52352 15.7767 2.52352 22.3301C2.64297 22.3301 2.76241 22.3301 2.88185 22.3301C8.73456 22.3301 14.4678 22.3301 20.3205 22.3301C20.9178 22.3301 21.2761 22.4515 21.6344 22.9369C22.1122 23.7864 21.6344 24.8786 20.5594 25H20.44C14.1095 25 7.77902 25 1.32909 25C0.612434 25 0.134662 24.6359 0.0152191 24.0291C0.0152191 23.9078 0.0152191 23.665 0.0152191 23.5437C0.0152191 16.2621 0.0152191 8.85922 0.0152191 1.57767C-0.104224 0.485437 0.492991 0 1.32909 0C7.65958 0 13.9901 0 20.3205 0C21.0372 0 21.515 0.364078 21.7539 0.970874C21.9927 1.69903 21.515 2.54854 20.6789 2.6699C20.5594 2.6699 20.44 2.6699 20.3205 2.6699C14.4678 2.6699 8.73456 2.6699 2.88185 2.6699C2.76241 2.6699 2.64297 2.6699 2.52352 2.6699Z" fill="#A4B3C5"/>
                                <path d="M19.3654 10.6797C19.1265 10.437 18.8876 10.3156 18.7682 10.0729C18.2904 9.58745 17.9321 9.22337 17.4543 8.73793C16.7376 8.00978 16.9765 6.79619 18.0515 6.55347C18.4098 6.43211 18.8876 6.55347 19.2459 6.79619C19.3654 6.79619 19.3654 6.91755 19.4848 6.91755C20.7987 8.2525 22.232 9.70881 23.5459 11.0438C23.9042 11.4078 24.1431 11.8933 23.9042 12.5001C23.7848 12.7428 23.6653 12.9855 23.5459 13.1069C22.232 14.5632 20.7987 15.8981 19.4848 17.3544C19.1265 17.7185 18.6487 17.8399 18.171 17.7185C17.6932 17.5972 17.3349 17.2331 17.3349 16.7476C17.2154 16.2622 17.4543 15.8981 17.6932 15.5341C18.2904 14.9273 18.8876 14.3205 19.4848 13.7137C19.4848 13.7137 19.6043 13.5923 19.7237 13.4709C19.6043 13.4709 19.4848 13.4709 19.3654 13.4709C16.1404 13.4709 12.9155 13.4709 9.57106 13.4709C8.8544 13.4709 8.37663 13.1069 8.25718 12.5001C7.77941 11.6506 8.25718 10.801 9.09328 10.801C9.21273 10.801 9.33217 10.801 9.45161 10.801C12.6766 10.801 15.9015 10.801 19.1265 10.801C19.1265 10.801 19.2459 10.801 19.3654 10.6797Z" fill="#A4B3C5"/>
                            </svg>
                        </div>

                    </div>
                    <div class="inline" v-if="$store.getters['common/personalManager']">
                        <div class="header-icon user">{{ $store.getters['common/personalManager'].name }}</div>
                        <a :href="'tel:' + getPhone($store.getters['common/personalManager'].phone)" class="header-icon phone">{{ $store.getters['common/personalManager'].phone }}
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.53312 7.94026C6.25213 8.89752 7.09096 9.73513 7.9298 10.4531C8.16947 10.2138 8.2893 9.97445 8.6488 9.85479C9.48764 9.13684 10.5661 9.13684 11.405 9.73513C11.8843 10.0941 12.4835 10.4531 12.9628 10.8121C14.1612 11.6497 14.0413 13.2052 13.0827 14.0428C12.3637 14.6411 11.5248 14.8804 10.5661 15.0001C9.48764 15.0001 8.40913 14.7608 7.33063 14.2822C5.89262 13.5642 4.69429 12.7266 3.73562 11.53C2.65711 10.3334 1.81828 9.13684 1.33894 7.58128C1.09927 6.74367 0.859606 5.90606 1.09927 4.94879C1.21911 4.11118 1.57861 3.27357 2.29761 2.67528C3.01661 2.07699 4.09512 2.07699 4.81412 2.67528C5.05379 2.9146 5.29346 3.27357 5.53312 3.51289C5.77279 3.87187 6.13229 4.23084 6.37196 4.70948C6.97113 5.7864 6.73146 7.22231 5.53312 7.94026C5.65296 7.94026 5.65296 7.94026 5.53312 7.94026ZM4.69429 11.171C5.53312 12.0086 6.37196 12.6069 7.33063 13.2052C8.2893 13.6839 9.3678 14.0428 10.5661 14.0428C11.405 14.0428 12.124 13.8035 12.6033 13.2052C12.9628 12.8463 12.843 12.1283 12.4835 11.7693C12.0041 11.4104 11.405 11.0514 10.9256 10.5727C10.2066 10.0941 9.48764 10.2138 9.0083 10.8121C8.88847 10.9317 8.76864 11.171 8.52897 11.4104C8.40913 11.6497 8.16947 11.7693 7.9298 11.53C7.5703 11.2907 7.09096 10.9317 6.73146 10.6924C5.77279 9.85479 5.05379 9.01718 4.45462 7.94026C4.33479 7.70094 4.33479 7.58128 4.57445 7.46162C4.81412 7.34196 4.93395 7.22231 5.17362 7.10265C5.77279 6.74367 5.89263 5.90606 5.53312 5.42743C5.17362 4.82914 4.69429 4.23084 4.21495 3.63255C3.97528 3.15392 3.73562 3.15392 3.37612 3.15392C3.01661 3.15392 2.89678 3.39323 2.65711 3.63255C2.17778 4.23084 2.05794 4.82914 2.05794 5.54709C2.05794 6.50435 2.29761 7.34196 2.53728 8.05991C3.01661 9.2565 3.85545 10.3334 4.69429 11.171Z" fill="#7789A0"/>
                                <path d="M5.53312 7.94026C6.25213 8.89752 7.09096 9.73513 7.9298 10.4531C8.16947 10.2138 8.2893 9.97445 8.6488 9.85479C9.48764 9.13684 10.5661 9.13684 11.405 9.73513C11.8843 10.0941 12.4835 10.4531 12.9628 10.8121C14.1612 11.6497 14.0413 13.2052 13.0827 14.0428C12.3637 14.6411 11.5248 14.8804 10.5661 15.0001C9.48764 15.0001 8.40913 14.7608 7.33063 14.2822C5.89263 13.5642 4.69429 12.7266 3.73562 11.53C2.65711 10.3334 1.81828 9.13684 1.33894 7.58128C1.09927 6.74367 0.859606 5.90606 1.09927 4.94879C1.21911 4.11118 1.57861 3.27357 2.29761 2.67528C3.01661 2.07699 4.09512 2.07699 4.81412 2.67528C5.05379 2.9146 5.29346 3.27357 5.53312 3.51289C5.77279 3.87187 6.13229 4.23084 6.37196 4.70948C6.97113 5.7864 6.73146 7.22231 5.53312 7.94026ZM5.53312 7.94026C5.65296 7.94026 5.65296 7.94026 5.53312 7.94026ZM4.69429 11.171C5.53312 12.0086 6.37196 12.6069 7.33063 13.2052C8.2893 13.6839 9.3678 14.0428 10.5661 14.0428C11.405 14.0428 12.124 13.8035 12.6033 13.2052C12.9628 12.8463 12.843 12.1283 12.4835 11.7693C12.0041 11.4104 11.405 11.0514 10.9256 10.5727C10.2066 10.0941 9.48764 10.2138 9.0083 10.8121C8.88847 10.9317 8.76864 11.171 8.52897 11.4104C8.40913 11.6497 8.16947 11.7693 7.9298 11.53C7.5703 11.2907 7.09096 10.9317 6.73146 10.6924C5.77279 9.85479 5.05379 9.01718 4.45462 7.94026C4.33479 7.70094 4.33479 7.58128 4.57445 7.46162C4.81412 7.34196 4.93395 7.22231 5.17362 7.10265C5.77279 6.74367 5.89263 5.90606 5.53312 5.42743C5.17362 4.82914 4.69429 4.23084 4.21495 3.63255C3.97528 3.15392 3.73562 3.15392 3.37612 3.15392C3.01661 3.15392 2.89678 3.39323 2.65711 3.63255C2.17778 4.23084 2.05794 4.82914 2.05794 5.54709C2.05794 6.50435 2.29761 7.34196 2.53728 8.05991C3.01661 9.2565 3.85545 10.3334 4.69429 11.171Z" stroke="#7789A0" stroke-width="0.4252" stroke-miterlimit="10"/>
                                <path d="M12.9624 3.03419C14.1608 4.23078 14.8798 5.66668 14.9996 7.46156C14.9996 7.58122 14.9996 7.70088 14.9996 7.82054C14.9996 8.05985 14.7599 8.29917 14.5203 8.29917C14.1608 8.29917 13.9211 8.17951 13.9211 7.94019C13.9211 7.70088 13.9211 7.3419 13.8012 7.10258C13.5616 5.54702 12.7227 4.23078 11.5244 3.27351C10.5657 2.55556 9.48723 2.07693 8.16906 2.07693C7.68973 2.07693 7.45006 1.59829 7.68973 1.23932C7.80956 1 7.92939 1 8.16906 1C9.3674 1 10.5657 1.35898 11.5244 2.07693C12.1236 2.31624 12.4831 2.67522 12.9624 3.03419Z" fill="#7789A0" stroke="#7789A0" stroke-width="0.4252" stroke-miterlimit="10"/>
                                <path d="M11.5247 4.58971C12.2437 5.42732 12.7231 6.38459 12.8429 7.46151C12.8429 7.82049 12.7231 8.05981 12.3636 8.05981C12.0041 8.05981 11.7644 7.94015 11.7644 7.58117C11.6446 7.10254 11.6446 6.6239 11.2851 6.14527C10.6859 5.06834 9.72723 4.35039 8.40905 4.23074C8.16939 4.23074 8.04955 4.11108 7.92972 3.99142C7.80989 3.7521 7.80989 3.51278 7.92972 3.39313C8.04955 3.15381 8.16939 3.15381 8.40905 3.15381C9.00822 3.27347 9.60739 3.39313 10.0867 3.63244C10.6859 3.87176 11.0454 4.23074 11.5247 4.58971Z" fill="#7789A0" stroke="#7789A0" stroke-width="0.4252" stroke-miterlimit="10"/>
                            </svg></a>
                    </div>
                </div>
            </div>
            <div class="inline phone-buttons">
                <a :href="'tel:' + getPhone($store.getters['common/personalManager'].phone)" class="phone-button phone" v-if="$store.getters['common/personalManager']">Звонок менеджеру</a>
                <div class="phone-button home" @click="$emit('openPopup', 'SelectPoint')">Выбрать аптеку</div>
            </div>
        </div>
        <MobileMenu v-if="$store.state.common.isPhone"
                    @closeMobileMenu="closeMobileMenu()"
                    @openPopup="openPopup"
                    @newLinesDownload="newLinesDownload"
                    @faqDownload="faqDownload"
                    :class="{_show: showMobileMenu}">
        </MobileMenu>
    </div>
</template>

<script>
import MobileMenu from '@/components/MobileMenu.vue'

export default {
    data () {
        return {
            showMobileMenu: false
        }
    },
    components: {
        MobileMenu
    },
    methods: {
        getPhone (phone) {
            if (phone.indexOf(' доп') != -1) phone = phone.split(' доп')[0]
            return phone.replace(/[{()}]|-|\s/g,"");
        },
        openPopup (data) {
            this.$emit('openPopup', data)
        },
        openMobileMenu () {
            this.showMobileMenu = true
        },
        closeMobileMenu () {
            this.showMobileMenu = false
        },
        newLinesDownload (e) {
            this.$emit('newLinesDownload', e)
        },
        faqDownload (e) {
            this.$emit('faqDownload', e)
        }
    }
}
</script>

<style lang="scss">
    @import "./../assets/styles/components/header.scss";
</style>