<template>
    <div class="popup mobile-popup" type="MessageSend" size="small">
        <div class="popup-header">
            <h1>Ваш запрос отправлен</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <p v-if="activePopup.message">{{ activePopup.message }}</p>
                <p v-else>Ваш запрос успешно отправлено! Наши менеджеры свяжутся с{{'\xa0'}}вами в{{'\xa0'}}ближайшее время.<br/>
                    Благодарим за&nbsp;обращения в{{'\xa0'}}нашу компанию!</p>
            </div>

            <div class="mobile-bottom inline">
                <div class="button" type="decline" @click="$emit('closePopup')">Окей</div>
            </div>
        </Component>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

    export default {
        props: ['activePopup', 'contentScroll'],
        components: {
            SmoothScrollbar
        }
    }
</script>