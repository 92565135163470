<template>
    <div class="blackout">
        <div v-click-outside="closePopup">
            <Component @closePopup="closePopup"
                       @openPopup="openPopup"
                       @callback="callback"
                       :contentScroll="contentScroll"
                       :activePopup="activePopup"
                       :is="activePopup.type">
            </Component>
        </div>
    </div>
</template>

<script>
import SelectPoint from '@/components/popups/SelectPoint.vue'
import Claim from '@/components/popups/Claim.vue'
import SearchInvoice from '@/components/popups/SearchInvoice.vue'
import ServiceClaim from '@/components/popups/ServiceClaim.vue'
import MessageSend from '@/components/popups/MessageSend.vue'
import RequestSend from '@/components/popups/RequestSend.vue'
import RequestInvoice from '@/components/popups/RequestInvoice.vue'
import ClaimCreated from '@/components/popups/ClaimCreated.vue'
import MessageBox from '@/components/popups/MessageBox.vue'
import Invoice from '@/components/popups/Invoice.vue'
import RequestPriceList from '@/components/popups/RequestPriceList.vue'
import Managers from '@/components/popups/Managers.vue'
import SendToManager from '@/components/popups/SendToManager.vue'
import Support from '@/components/popups/Support.vue'
import PollStart from '@/components/popups/PollStart.vue'
import PollDone from '@/components/popups/PollDone.vue'

export default {
    props: ['activePopup'],
    components: {
        SelectPoint,
        Claim,
        SearchInvoice,
        ServiceClaim,
        MessageSend,
        RequestSend,
        RequestInvoice,
        ClaimCreated,
        MessageBox,
        Invoice,
        RequestPriceList,
        Managers,
        SendToManager,
        Support,
        PollStart,
        PollDone
    },
    methods: {
        closePopup (flag) {
            this.$emit('closePopup', flag)
        },
        openPopup (data) {
            this.$emit('openPopup', data)
        },
        callback (data) {
            if (this.activePopup.callback)
                this.activePopup.callback(data);
        }
    },
    computed: {
        contentScroll () {
            if (this.$store.state.common.isPhone) {
                return 'div'
            } else return 'SmoothScrollbar'
        }
    }
}
</script>

<style lang="scss">
    @import "./../assets/styles/components/popups.scss";
</style>