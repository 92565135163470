<template>
    <div>
        <div class="switcher-group column">
            <div class="button popup-switcher" type="get" @click="showMobilePopup('report')">Запросить отчёт</div>
            <div class="button popup-switcher" type="get" @click="showMobilePopup('shedule')">Запросить график платежей</div>
        </div>

        <div class="inline receivables-content" valign="top">
            <div class="column about">
                <strong v-if="requisites.status == 'loading'" class="loading">Загрузка реквизитов</strong>
                <div v-else>
                    <div v-if="requisites.data.company">
                        <strong>Компания:</strong>
                        <p v-html="requisites.data.company"></p>
                    </div>

                    <div v-if="requisites.data.address">
                        <strong>Адрес:</strong>
                        <p v-html="requisites.data.address"></p>
                    </div>

                    <div v-if="requisites.data.inn && requisites.data.kpp">
                        <strong>ИНН/КПП:</strong>
                        <p>{{ requisites.data.inn }}/{{ requisites.data.kpp }}</p>
                    </div>

                    <div v-if="requisites.data.rs">
                        <strong>Р/с:</strong>
                        <p>{{ requisites.data.rs }}<br/>
                            {{ requisites.data.bank }}</p>
                    </div>

                    <div v-if="requisites.data.ks">
                        <strong>К/с:</strong>
                        <p>{{ requisites.data.ks }}</p>
                    </div>

                    <div v-if="requisites.data.bik">
                        <strong>БИК:</strong>
                        <p>{{ requisites.data.bik }}</p>
                    </div>

                    <div v-if="requisites.data.email">
                        <strong>Email:</strong>
                        <p><a :href="requisites.data.email" class="link">{{ requisites.data.email }}</a></p>
                    </div>

                    <div v-if="requisites.data.phone">
                        <strong>Телефон:</strong>
                        <p v-html="requisites.data.phone"></p>
                    </div>
                </div>
            </div>
            <div class="inline blocks-wrapper">
                <div class="inline blocks">
                    <div class="content-block inline mobile-popup" halign="between" :status="report.status" :class="{_show: mobile_popup == 'report'}">
                        <div class="column">
                            <div class="popup-header" v-if="$store.state.common.isPhone">
                                <h1>Запросить акт сверки</h1>
                                <div class="close" @click="hideMobilePopup()"></div>
                            </div>
                            <h4 v-else>Запросить акт сверки</h4>
                            <div class="form-item">
                                <Select size="full" :data.sync="report.division"></Select>
                                <label>Подразделение</label>
                            </div>

                            <div class="range" size="full">
                                <Datepicker :value="report.date_from" disabled="true" @dateUpdate="dateUpdateFrom"></Datepicker>
                                <span>&mdash;</span>
                                <Datepicker :value="report.date_to" disabled="true" @dateUpdate="dateUpdateTo"></Datepicker>
                            </div>

                            <p class="error" :class="{_show: report.show_error && report.error_msg}">{{ report.error_msg }}</p>
                            <div class="inline mobile-bottom" halign="right">
                                <div class="button" type="confirm" @click="getReport()" :class="{disabled: !report.division.value}">Получить акт</div>
                            </div>
                        </div>
                        <!--
                        <div class="column rounded">
                            <Select size="full" :data.sync="division"></Select>

                            <input type="text" class="text-input" placeholder="Email" />

                            <Select size="full" :data.sync="division"></Select>

                            <div class="inline" halign="between">
                                <span class="setting">Настроить</span>
                                <div class="button" type="confirm">Отправить</div>
                            </div>
                        </div>
                        -->
                    </div>
                    <div class="content-block inline mobile-popup" halign="between" :status="shedule.status" :class="{_show: mobile_popup == 'shedule'}">
                        <div class="column">
                            <div class="popup-header" v-if="$store.state.common.isPhone">
                                <h1>Запросить график платежей</h1>
                                <div class="close" @click="hideMobilePopup()"></div>
                            </div>
                            <h4 v-else>Запросить график платежей</h4>

                            <div class="column">
                                <div class="form-item">
                                    <Select size="full" :data.sync="shedule.division"></Select>
                                    <label>Подразделение</label>
                                </div>
                                <div class="content-block-bottom">
                                    <p class="error" :class="{_show: shedule.show_error && shedule.error_msg}">{{ shedule.error_msg }}</p>
                                    <div class="inline mobile-bottom" halign="right">
                                        <div class="button" type="confirm" @click="getShedule()" :class="{disabled: !shedule.division.value}">Получить график</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <!--
                        <div class="column rounded">
                            <Select size="full" :data.sync="division"></Select>

                            <input type="text" class="text-input" placeholder="Email" />

                            <Select size="full" :data.sync="division"></Select>

                            <div class="inline" halign="between">
                                <span class="setting">Настроить</span>
                                <div class="button" type="confirm">Отправить</div>
                            </div>
                        </div>
                        -->
                    </div>
                </div>                
                <div class="desktop-only block-notes">*срок формирования акта сверки не ранее 15 числа, следующего за отчетным</div>                
            </div>
        </div>
    </div>
</template>

<script>
import Select from '@/components/_Select.vue'
import Datepicker from '@/components/Datepicker.vue'
import FileSaver from 'file-saver'

export default {
    metaInfo: {
        title: 'Дебиторская задолженность'
    },
    components: {
        Select,
        Datepicker
    },
    data () {
        return {
            mobile_popup: false,
            requisites: {
                status: '',
                data: {}
            },
            shedule: {
                status: '',
                division: {
                    open: false,
                    value: '',
                    list: [],
                    text_value: ''
                },
                show_error: false,
                show_error_tm: false,
                error_msg: ''
            },
            report: {
                status: '',
                date_from: this.moment().subtract(1, 'year'),
                date_to: this.moment(),
                division: {
                    open: false,
                    value: '',
                    list: [],
                    text_value: ''
                },
                show_error: false,
                show_error_tm: false,
                error_msg: ''
            }
        }
    },
    computed: {
        clientId () {
            return this.$store.state.common.client.id
        }
    },
    watch: {
        clientId () {
            this.getRequisites()
        }
    },
    methods: {
        showError (type, msg = '') {
            this[type].error_msg = msg
            this[type].show_error = true
            if (this[type].show_error_tm) clearTimeout(this[type].show_error_tm)
            this[type].show_error_tm = setTimeout(() => {
                this[type].show_error = false
                this[type].error_msg = ''
            }, 5000)
        },
        showMobilePopup (type) {
            this.mobile_popup = type
            document.documentElement.style.overflow = 'hidden'
        },
        hideMobilePopup () {
            this.mobile_popup = false
            document.documentElement.style.overflow = 'auto'
        },
        dateUpdateFrom(date) {
            this.report.date_from = date
        },
        dateUpdateTo(date) {
            this.report.date_to = date
        },
        getRequisites() {
            this.requisites.status = 'loading'
            this.axios({url: 'api/v1/receivable/getrequisites?clientId=' + this.$store.state.common.client.id, method: 'GET'})
                .then(resp => {
                    this.requisites.status = ''
                    this.requisites.data = resp.data
                })
        },
        getReportDivisions () {
            this.report.status = 'loading'
            this.axios({url: 'api/v1/receivable/getreconciliationactunits', method: 'GET'})
                .then(resp => {
                    this.report.status = ''
                    this.report.division.list = this.correctDivisions(resp.data)
                })
        },
        getSheduleDivisions () {
            this.shedule.status = 'loading'
            this.axios({url: 'api/v1/receivable/getpaymentscheduleunits', method: 'GET'})
                .then(resp => {
                    this.shedule.status = ''
                    this.shedule.division.list = this.correctDivisions(resp.data)
                })
        },
        correctDivisions (divisions) {
            let result = []
            for (let key in divisions) {
                result.push({
                    id: key,
                    value: divisions[key]
                })
            }
            return result
        },
        getReport() {
            this.report.show_error = false
            this.report.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                ownerId: this.report.division.value,
                dateFrom: this.report.date_from,
                dateTo: this.report.date_to
            }
            this.axios({url: 'api/v1/receivable/getreconciliationactreport', data:data, method: 'POST', responseType: 'blob'})
                .then(resp => {
                    this.report.status = ''
                    const filename = 'regular_report.zip'

                    const blob = new Blob([resp.data], {
                        type: 'application/zip'
                    })
                    FileSaver.saveAs(blob, filename)
                }).catch(e => {
                    this.report.status = ''
                    const reader = new FileReader();
                    let self = this
                    reader.onload = function() {
                        const data = JSON.parse(this.result);
                        self.showError('report', data.Message)
                    };
                    reader.readAsText(e.response.data)
                })
        },
        getShedule() {
            this.shedule.show_error = false
            this.shedule.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                ownerId: this.shedule.division.value,
            }
            this.axios({url: 'api/v1/receivable/getpaymentschedulereport', data:data, method: 'POST', responseType: 'blob'})
                .then(resp => {
                    this.shedule.status = ''
                    const filename = 'payment_shedule.zip'

                    const blob = new Blob([resp.data], {
                        type: 'application/zip'
                    })
                    FileSaver.saveAs(blob, filename)
                }).catch(e => {
                    this.shedule.status = ''
                    const reader = new FileReader();
                    let self = this
                    reader.onload = function() {
                        const data = JSON.parse(this.result);
                        self.showError('shedule', data.Message)
                    };
                    reader.readAsText(e.response.data)
                })
        },
    },
    mounted () {
        this.getRequisites()
        this.getReportDivisions()
        this.getSheduleDivisions()
    }
}
</script>

<style lang="scss">
    @import "./../assets/styles/views/receivables.scss";
</style>