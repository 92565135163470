<template>
    <div class="popup mobile-popup" type="SelectPoint" size="small">
        <div class="popup-header">
            <h1>Выберите аптеку</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <div class="loading" v-if="status == 'loading'">Загрузка</div>
                <div class="clients-list" v-else>
                    <div>
                        <input id="search" type="text" class="text-input" v-model.trim="search" />
                    </div>
                    <strong v-if="!filteredClients.length" class="no-items">Не найдено доступных сетей</strong>
                    <div v-else class="client padded"
                         v-for="(item, key) in filteredClients"
                         :key="key"
                         :class="{active: item.id == $store.state.common.client.id}"
                         @click="setClient(item)">

                            {{ item.name }}
                    </div>
                </div>
            </div>

            <div class="mobile-bottom">
                <div class="button" type="decline" @click="$emit('closePopup')">Отменить</div>
            </div>
        </Component>
    </div>
</template>

<script>
import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

export default {
    props: ['contentScroll'],
    components: {
        SmoothScrollbar,
    },
    data () {
        return {
            clients: [],
            status: '',
            search: ''
        }
    },
    computed: {
        filteredClients: function () {
            var self = this
            return this.clients.filter(function (client) { 
				return client.name.toLowerCase().includes(self.search.toLowerCase()) 
            })
        }
    },
    methods: {
        setClient (data) {
            this.$store.commit('common/SET_CLIENT', data)
            localStorage.setItem('selectedPoint', data.id)
            this.$emit('closePopup')
        },
        getClients () {
            this.status = 'loading'
            let getClientsUrl = "api/v1/user/getallclientsbycurrentholding";

            this.axios({url: getClientsUrl, method: 'GET'})
                .then(resp => {
                    this.status = ''
                    this.clients = resp.data
                }).catch(() => {
                    this.status = ''
                })
        }
    },
    mounted () {
        this.getClients()
    }
}
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_SelectPoint.scss";
</style>