<template>
    <div class="breadcrumbs">
        <div class="wrapper">
            <router-link class="link" to="/">Веб-кабинет</router-link> &mdash; <span>{{ routeName }}</span>
        </div>
    </div>
</template>

<script>
 export default {
     props: ['routeName']
 }
</script>