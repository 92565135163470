<template>
    <div>
        <div class="switcher-group">
            <div class="button popup-switcher" @click="showTab('qualityDocPkgs')" :class="{active: tab == 'qualityDocPkgs'}" type="qualityDocPkgs">Пакеты документов</div>
            <div class="button popup-switcher"  @click="showTab('quality')" :class="{active: tab == 'quality'}" type="quality">Документы качества</div>
        </div>

        <div class="phone-only btn-mobile-wrapper">
            <div class="button popup-switcher"  @click="showMobilePopup()" type="search">Найти</div>
            <div v-if="tab == 'qualityDocPkgs'"
                 :class="{disabled: noOneSelected || docPkgs.status == 'loading'}" 
                 @click="downloadSelected()" class="button" type="download">
                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.92055 13.0849C6.87781 13.1451 6.82947 13.2025 6.77553 13.2565C6.25574 13.7763 5.413 13.7763 4.89321 13.2565L0.389842 8.7531C-0.129947 8.23331 -0.129948 7.39056 0.389842 6.87078C0.909632 6.35099 1.75238 6.35099 2.27217 6.87078L4.54756 9.14617L4.54756 1.331C4.54756 0.59591 5.14347 0 5.87856 0C6.61366 0 7.20957 0.595911 7.20957 1.331L7.20957 9.03128L9.33598 6.90487C9.85577 6.38508 10.6985 6.38507 11.2183 6.90486C11.7381 7.42465 11.7381 8.2674 11.2183 8.78719L6.92055 13.0849Z" fill="#8AC148"></path></svg>
                Скачать выбранное
            </div>
        </div>

        <div class="filter mobile-popup" type="quality" padding="low" v-if="tab == 'quality'" :class="{_show: tab == 'quality' && mobile_popup}">
            <div class="popup-header phone-only">
                <h1>Найти</h1>
                <div class="close" @click="hideMobilePopup()"></div>
            </div>

            <div class="inline" halign="between">
                <div class="filter-left column">
                    <div class="inline" valign="bottom">
                        <div class="form-item" size="medium">
                            <input type="text" class="text-input" id="inv-number" v-model.trim="filter.invoice" required />
                            <label for="inv-number">№ счёта-фактуры</label>
                        </div>
                        <div class="inline mob-col" valign="bottom" halign="between">
                            <div class="form-item">
                                <input id="name" type="text" class="text-input" v-model.trim="filter.name" required />
                                <label for="name">Наименование</label>
                            </div>
                            <div class="form-item">
                                <input type="text" id="filter-manuf" class="text-input" v-model.trim="filter.manufacturer" required />
                                <label for="filter-manuf">Производитель</label>
                            </div>
                        </div>
                    </div>
                    <div class="inline">
                        <div class="range">
                            <Datepicker placeholder="от" :value="filter.date_from" @dateUpdate="dateUpdateFrom"></Datepicker>
                            <span>&mdash;</span>
                            <Datepicker placeholder="до" :value="filter.date_to" @dateUpdate="dateUpdateTo"></Datepicker>
                        </div>
                    </div>
                </div>
                <div class="column right bottom mobile-bottom">
                    <div class="button" type="decline" @click="resetFilter()">Очистить</div>
                    <div class="button" type="confirm" :class="{disabled: docs.status == 'loading'}" @click="getDocsAndReset()">Найти</div>
                </div>
            </div>
        </div>

        <div class="table" v-if="tab == 'quality'">
            <strong class="loading" v-if="docs.status == 'loading' && !docs.list.length">Загрузка списка документов</strong>
            <div v-else>
                <div v-if="!docs.list.length">
                    <strong class="no-items">Документы не найдены</strong>
                </div>

                <Table v-else
                       ref="dataTable"
                       @getData="getTableData"
                       @openPopup="openPopup"
                       defaultSort="invoiceNumber"
                       :data="docs"
                       :schema="[
                            {id: 'invoiceNumber', name: '№ с/ф', quality: true},
                            {id: 'invoiceDate', name: 'Дата'},
                            {id: 'invoiceSumWithVat', name: 'Сумма', align: 'right'}
                       ]">
                </Table>
            </div>
        </div>

        <div class="filter mobile-popup" type="qualityDocPkgs" padding="low" v-if="tab == 'qualityDocPkgs'" :class="{_show: tab == 'qualityDocPkgs' && mobile_popup}">
            <div class="popup-header phone-only">
                <h1>Найти</h1>
                <div class="close" @click="hideMobilePopup()"></div>
            </div>

            <div class="inline" halign="between">
                <div class="filter-left column">
                    <div class="inline" valign="bottom">
                        <div class="form-item" size="medium">
                            <input type="text" class="text-input" id="inv-number" v-model.trim="filterDocPkgs.invoice" required />
                            <label for="inv-number">№ счёта-фактуры</label>
                        </div>
                        <div class="inline mob-col" valign="bottom" halign="between">
                            <div class="form-item">
                                <input id="name" type="text" class="text-input" v-model.trim="filterDocPkgs.name" required />
                                <label for="name">Наименование</label>
                            </div>
                            <div class="form-item">
                                <input type="text" id="filter-manuf" class="text-input" v-model.trim="filterDocPkgs.manufacturer" required />
                                <label for="filter-manuf">Производитель</label>
                            </div>
                        </div>
                    </div>
                    <div class="inline">
                        <div class="range">
                            <Datepicker placeholder="от" :value="filterDocPkgs.date_from" @dateUpdate="dateUpdateFrom"></Datepicker>
                            <span>&mdash;</span>
                            <Datepicker placeholder="до" :value="filterDocPkgs.date_to" @dateUpdate="dateUpdateTo"></Datepicker>
                        </div>
                    </div>
                </div>
                <div class="column right bottom mobile-bottom">
                    <div class="button" type="decline" @click="resetFilter()">Очистить</div>
                    <div class="button" type="confirm" :class="{disabled: docPkgs.status == 'loading'}" @click="getDocsAndReset()">Найти</div>
                </div>
            </div>
            <div halign="right" class="inline btn-wrapper desktop-only">
                <div :class="{disabled: noOneSelected || docPkgs.status == 'loading'}" @click="downloadSelected()" class="button" type="download"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.92055 13.0849C6.87781 13.1451 6.82947 13.2025 6.77553 13.2565C6.25574 13.7763 5.413 13.7763 4.89321 13.2565L0.389842 8.7531C-0.129947 8.23331 -0.129948 7.39056 0.389842 6.87078C0.909632 6.35099 1.75238 6.35099 2.27217 6.87078L4.54756 9.14617L4.54756 1.331C4.54756 0.59591 5.14347 0 5.87856 0C6.61366 0 7.20957 0.595911 7.20957 1.331L7.20957 9.03128L9.33598 6.90487C9.85577 6.38508 10.6985 6.38507 11.2183 6.90486C11.7381 7.42465 11.7381 8.2674 11.2183 8.78719L6.92055 13.0849Z" fill="#8AC148"></path></svg>Скачать выбранное</div>
            </div>
        </div>

        <div class="table" v-if="tab == 'qualityDocPkgs'">
            <strong class="loading" v-if="docPkgs.status == 'loading' && !docPkgs.list.length">Загрузка списка документов</strong>
            <div v-else>
                <div v-if="!docPkgs.list.length">
                    <strong class="no-items">Документы не найдены</strong>
                </div>

                <Table v-else
                       ref="pkgsDataTable"
                       @getData="getTableData"
                       @openPopup="openPopup"
                       defaultSort="invoiceNumber"
                       tableName="qualityDocPkgs"
                       :data.sync="docPkgs"
                       :schema="[
                            {id: 'invoiceNumber', name: '№ с/ф', quality: true, checkbox: true},
                            {id: 'invoiceDate', name: 'Дата'},
                            {id: 'existsInvoice', name: 'Скачать с/ф', quality: true, noSort: true},
                            {id: 'existsTorg12', name: 'Скачать торг-12', quality: true, noSort: true},
                            {id: 'existsProtocol', name: 'Скачать протокол', quality: true, noSort: true},                            
                            {id: 'invoiceSumWithVat', name: 'Сумма', align: 'right'}
                       ]">
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import Table from '@/components/Table.vue'
import FileSaver from 'file-saver'

export default {
    metaInfo: {
        title: 'Документы качества'
    },
    components: {
        Datepicker,
        Table
    },
    data () {
        return {
            mobile_popup: false,
            tab: 'quality',
            filter: {
                invoice: '',
                name: '',
                manufacturer: '',
                date_from: '',
                date_to: '',
            },
            filterDocPkgs: {
                invoice: '',
                name: '',
                manufacturer: '',
                date_from: '',
                date_to: '',
            },
            docs: {
                status: '',
                list: [],
                count: null
            },
            docPkgs: {
                status: '',
                list: [],
                count: null
            }
        }
    },
    computed: {
        clientId () {
            return this.$store.state.common.client.id
        },
        noOneSelected () {
            for (let item of this.docPkgs.list) {
                if (item.checked && (item.invoiceChecked || item.torg12Checked || item.protocolChecked)) {
                    return false
                }
            }
            return true
        },
        query () {
            return this.$route.query;
        }
    },
    watch: {
        clientId () {
            this.getDocs()
        },
        tab () {
            this.getDocs()
        },
        query () {
            this.initTab()
            document.getElementById('app').scrollTo(0,0)
        }
    },
    methods: {
        openPopup (data) {
            this.$emit('openPopup', data)
        },
        getFilter() {
            if (this.tab == 'qualityDocPkgs') {
                return this.filterDocPkgs;
            } else {
                return this.filter;
            }
        },
        getDataTable() {
            if (this.tab == 'qualityDocPkgs') {
                return this.$refs.pkgsDataTable;
            } else {
                return this.$refs.dataTable;
            }
        },
        getDocsAndReset () {
            this.getDocs()
            const dataTable = this.getDataTable();
            if (dataTable) dataTable.resetPaginator()
        },
        resetFilter () {
            const dataTable = this.getDataTable();
            dataTable.sort.name = 'invoiceNumber'
            dataTable.sort.direction = 'DESC'

            const filter = this.getFilter();
            filter.date_from = ''
            filter.date_to = ''
            filter.invoice = ''
            filter.name = ''
            filter.manufacturer = ''

            this.getDocs()
        },
        showTab (type) {
            this.tab = type
        },
        showMobilePopup () {
            this.mobile_popup = true
            document.documentElement.style.overflow = 'hidden'
        },
        hideMobilePopup () {
            this.mobile_popup = false
            document.documentElement.style.overflow = 'auto'
        },
        getTableData (e) {
            this.getDocs(e.paginator, e.sort)
        },
        dateUpdateFrom (date) {
            const filter = this.getFilter();
            filter.date_from = date
        },
        dateUpdateTo (date) {
            const filter = this.getFilter();
            filter.date_to = date
        },
        getDocs (paginator = false, sort = false) {
            if (this.tab == 'qualityDocPkgs') {
                this.getDocPackages(paginator, sort);
            } else {
                this.getQualityDocs(paginator, sort);
            }
        },
        getQualityDocs(paginator = false, sort = false) {
            let sorting = sort ? sort : (this.$refs.dataTable && this.$refs.dataTable.sort ? this.$refs.dataTable.sort : false)
            this.docs.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                page: paginator ? paginator.current_page : 1,
                itemsPerPage: paginator ? paginator.per_page.value : 10,
                sortBy: sorting ? sorting.name : 'invoiceNumber',
                sortDesc: sorting ? (sorting.direction == 'DESC' ? true : false) : true,
                invoiceNumber: this.filter.invoice,
                productName: this.filter.name,
                manufacturer: this.filter.manufacturer,
                dateFrom: this.filter.date_from != '' ? this.filter.date_from : null,
                dateTo: this.filter.date_to != '' ? this.filter.date_to : null
            }
            this.axios({url: 'api/v1/qualitydocs/getinvoices', data: data, method: 'POST'})
                .then(resp => {
                    this.docs.status = ''
                    this.docs.list = resp.data.data
                    this.docs.count = resp.data.count
                }).catch(() => {
                    this.docs.status = ''
                })
        },
        correctData (data) {
            let count = 0
            for (let item of data) {
                item.checked = false
                item.invoiceChecked = false
                item.torg12Checked = false
                item.protocolChecked = false
                item.id = count
                count++
            }
            return data
        },
        getDocPackages(paginator = false, sort = false) {
            let sorting = sort ? sort : (this.$refs.pkgsDataTable && this.$refs.pkgsDataTable.sort ? this.$refs.pkgsDataTable.sort : false)
            this.docPkgs.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                page: paginator ? paginator.current_page : 1,
                itemsPerPage: paginator ? paginator.per_page.value : 10,
                sortBy: sorting ? sorting.name : 'invoiceNumber',
                sortDesc: sorting ? (sorting.direction == 'DESC' ? true : false) : true,
                invoiceNumber: this.filterDocPkgs.invoice,
                productName: this.filterDocPkgs.name,
                manufacturer: this.filterDocPkgs.manufacturer,
                dateFrom: this.filterDocPkgs.date_from != '' ? this.filterDocPkgs.date_from : null,
                dateTo: this.filterDocPkgs.date_to != '' ? this.filterDocPkgs.date_to : null
            }
            this.axios({url: 'api/v1/qualitydocs/getDocPackages', data: data, method: 'POST'})
                .then(resp => {
                    this.docPkgs.status = ''
                    this.docPkgs.list = this.correctData(resp.data.data)
                    this.docPkgs.count = resp.data.count
                }).catch(() => {
                    this.docPkgs.status = ''
                })
        },
        downloadSelected() {
            this.docPkgs.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                orderDocs: this.getCheckedOrderDocs()
            }

            this.axios({url: 'api/v1/qualitydocs/getDocPackage', data: data, method: 'POST', responseType: 'blob'})
                .then(resp => {
                    this.docPkgs.status = ''
                    const filename = 'doc_package.zip'
                    const blob = new Blob([resp.data], {
                        type: 'application/zip'
                    })
                    FileSaver.saveAs(blob, filename)
                }).catch(() => {
                    this.docPkgs.status = ''
                })
        },
        getCheckedOrderDocs () {
                let result = []
                for (let item of this.docPkgs.list) {
                    if (item.checked) {
                        const order = {
                            orderCode: item.orderCode,
                            downloadInvoice: item.invoiceChecked,
                            downloadTorg12: item.torg12Checked,
                            downloadProtocol: item.protocolChecked
                        }
                        result.push(order)
                    }
                }
                if (result.length) return result
                return null
            },
        initTab() {
            const tab = this.$route.query?.tab;
            if (tab)
            {
                this.showTab(tab);
                let query = Object.assign({}, this.$route.query);
                delete query.tab;
                this.$router.replace({ query });
            }
        }
    },
    mounted () {
        this.initTab()
        this.getDocs()
    }
}
</script>

<style lang="scss">
    @import "./../assets/styles/views/quality.scss";
</style>