<template>
    <div class="popup mobile-popup" type="searchInvoice" size="big">
        <div class="popup-header">
            <h1>Найти накладную</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <div class="inline mob-col" valign="bottom" halign="between">
                    <div class="form-item" size="small">
                        <input id="invoice_number" type="text" class="text-input" v-model.trim="invoice" required />
                        <label for="invoice_number">№ накладной</label>
                    </div>

                    <div class="range">
                        <Datepicker :value="date_from" @dateUpdate="dateUpdateFrom" :disabledBeforeDates="disabledBeforeDates_from"></Datepicker>
                        <span>&mdash;</span>
                        <Datepicker :value="date_to" @dateUpdate="dateUpdateTo" :disabledBeforeDates="disabledBeforeDates_to"></Datepicker>
                    </div>
                    <div class="button" type="confirm" :class="{disabled: invoices.status == 'loading'}" @click="getInvoicesAndReset()">Найти</div>
                </div>

                <div class="table">
                    <strong class="loading" v-if="invoices.status == 'loading' && !invoices.list.length">Загрузка списка накладных</strong>
                    <div v-else>
                        <div v-if="!invoices.list.length">
                            <strong class="no-items">Накладные не найдены</strong>
                        </div>

                        <Table v-else
                               ref="dataTable"
                               @getData="getTableData"
                               @selectInvoice="selectInvoice"
                               perPageAlign="top"
                               :data="invoices"
                               defaultSort="invoiceDate"
                               defaultDirection="DESC"
                               :schema="[
                                    {id: 'invoiceNumber', name: '№ накладной', searchInvoice: true},
                                    {id: 'invoiceDate', name: 'Дата'},
                                    {id: 'invoiceSumWithVat', name: 'Сумма c НДС', align: 'right'}
                               ]">
                        </Table>
                    </div>
                </div>
            </div>
        </Component>
    </div>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'
import Table from '@/components/Table.vue'

export default {
    props: ['contentScroll', 'activePopup'],
    data () {
        const claimType = this.activePopup.claimType;
        
        return {
            invoice: '',
            date_from: this.moment().subtract(30, 'days'),
            disabledBeforeDates_from: claimType.value == 4 // Возврат брака серии
                ? undefined
                : this.moment().subtract(30, 'days'),
            disabledBeforeDates_to: claimType.value == 4 // Возврат брака серии
                ? undefined
                : this.moment().subtract(30, 'days'),
            date_to: this.moment(),
            invoices: {
                status: '',
                list: [],
                count: null
            },
            details: {},
        }
    },
    components: {
        SmoothScrollbar,
        Datepicker,
        Table
    },
    computed: {
        clientId () {
            return this.$store.state.common.client.id
        }
    },
    watch: {
        clientId () {
            this.getInvoices()
        }
    },
    methods: {
        getInvoicesAndReset () {
            this.getInvoices()
            if (this.$refs.dataTable) this.$refs.dataTable.resetPaginator()
        },
        selectInvoice (data) {
            if (this.activePopup.useCallback) {
                this.$emit('callback', data)
            } else {
                this.$store.commit('common/CREATE_CLAIM_INVOICE', {
                    orderCode: data.orderCode,
                    invoice: data.invoice
                })
            }

            this.$emit('closePopup')
        },
        dateUpdateFrom (date) {
            this.date_from = date
            this.disabledBeforeDates_to = this.moment(date).add(1,'days')
            
            if (this.moment(date).isAfter(this.date_to)) {
                this.date_to = date
            }
        },
        dateUpdateTo (date) {
            this.date_to = date
        },
        getTableData (e) {
            this.getInvoices(e.paginator, e.sort)
        },
        getInvoices (paginator = false, sort = false) {
            this.invoices.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                page: paginator ? paginator.current_page : 1,
                itemsPerPage: paginator ? paginator.per_page.value : 10,
                sortBy: sort ? sort.name : null,
                sortDesc: sort ? (sort.direction == 'DESC' ? true : false) : null,
                invoiceNumber: this.invoice ? this.invoice : null,
                productName: null,
                manufacturer: null,
                dateFrom: this.date_from,
                dateTo: this.date_to
            }

            this.axios({url: 'api/v1/claim/getinvoices', data: data, method: 'POST'})
                .then(resp => {
                    this.invoices.status = ''
                    this.invoices.list = resp.data.data
                    this.invoices.count = resp.data.count
                }).catch(() => {
                    this.invoices.status = ''
                })
        }
    },
    mounted () {
        this.getInvoices(false, {"name": "invoiceDate", "direction": "DESC"})
        //this.getInvoices()
    }
}
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_SearchInvoice.scss";
</style>