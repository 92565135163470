<template>
    <div class="table-container" :status="data.status">
        <div class="table-wrapper">
            <table>
                <tr class="table-header">
                    <td v-for="(item, key) in schema" :key="key" :type="item.id" :class="item.align">
                        <div class="checkbox" v-if="item.checkbox" :class="{sort: sort.name == item.id}" :sort="sort.direction">
                            <input type="checkbox" :checked="selectedAll" :id="item.id" @change="changeSelectAll" />
                            <label class="no-hover" :for="item.id"><span @click.prevent="toggleSort(item.id)">{{ item.name }}</span></label>
                        </div>
                        <span v-else-if="item.noSort">{{ item.name }}</span>
                        <span v-else :class="{sort: sort.name == item.id, offset: item.radio}" :sort="sort.direction" @click="toggleSort(item.id)">
                            {{ item.name }}
                        </span>
                    </td>
                </tr>
                <tr v-for="(item, key) in data.list" :key="key">
                    <td v-for="(schema_item, schema_key) in schema" :key="schema_key" :type="schema_item.id" :class="schema_item.align">

                        <div class="checkbox round" v-if="schema_item.radio">
                            <input type="radio" :id="schema_item.id + item.invoiceNumber" v-bind:value="item.invoiceNumber" v-model="selectRadio" @change="selectRadioItem(item)" name="table-radio" />
                            <label :for="schema_item.id + item.invoiceNumber">{{ item[schema_item.id] }}</label>
                        </div>

                        <div class="checkbox" v-else-if="schema_item.checkbox">
                            <input type="checkbox" v-model="item.checked" :id="schema_item.id + key" @change="changeListItem(item)" />
                            <label :for="schema_item.id + key">{{ item[schema_item.id] }}</label>
                        </div>

                        <Select v-else-if="schema_item.id == 'seriesList'" @select="selectSeries(schema_item.id, item)" size="small" :class="{top: key == data.list.length - 1}" :data.sync="item[schema_item.id]"></Select>

                        <div v-else-if="schema_item.id == 'passportCount' || schema_item.id == 'certCount'">
                            <div v-if="getCheckboxIsDefect(schema_item.id, item.seriesNumber, item)" class="checkbox solo isDefect"></div>
                            <div v-else class="checkbox solo" :class="{_active: getCheckboxStatus(schema_item.id, item)}" @click="toggleCheckboxStatus(schema_item.id, item)"></div>
                        </div>

                        <span v-else-if="schema_item.id == 'claimNumber'"
                              @click="$emit('openPopup', {type: 'claim', id: item[schema_item.id], guId: item['claimGuid']})"
                              class="link">{{ item[schema_item.id] }}</span>

                        <span v-else-if="schema_item.id == 'claimDate' || schema_item.id == 'invoiceDate'">{{ item[schema_item.id] | formatDate }}</span>

                        <span v-else-if="schema_item.id == 'sumWithVat' || schema_item.id == 'price' || schema_item.id == 'invoiceSumWithVat' || schema_item.id == 'boomerangSum'">{{ item[schema_item.id] | price }}</span>

                        <span v-else-if="schema_item.id == 'invoiceNumber' && schema_item.searchInvoice"
                              @click="$emit('selectInvoice', {
                                orderCode: item['orderCode'],
                                invoice: item[schema_item.id]
                              })"
                              class="link">{{ item[schema_item.id] }}</span>

                        <span v-else-if="schema_item.id == 'invoiceNumber' && schema_item.quality"
                              @click="$emit('openPopup', {
                                type: 'Invoice',
                                id: item[schema_item.id],
                                orderCode: item['orderCode']
                              })"
                              class="link">{{ item[schema_item.id] }}</span>

                        <div class="checkbox col-checkbox"
                            v-else-if="(schema_item.id == 'existsInvoice' || schema_item.id == 'existsTorg12' || schema_item.id == 'existsProtocol')  && schema_item.quality">
                            <div v-if="!item[schema_item.id]" class="checkbox solo isDefect"></div>
                            <div v-else class="checkbox solo" :class="{_active: getCheckboxStatus(schema_item.id, item)}" @click="toggleCheckboxStatus(schema_item.id, item)"></div>
                        </div>

                        <span v-else>{{ item[schema_item.id] }}</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="table-control inline mob-col" halign="center">
            <div class="table-paginator inline free" valign="center">
                <div class="table-paginator-arrow _left" :class="{hide: paginator.current_page == '' || paginator.current_page == 1}" @click="prevPage()">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 9.10472L8 0.89379C8 0.561927 7.81031 0.255043 7.50752 0.101601C7.20474 -0.051841 6.83995 -0.0304305 6.5627 0.158696L0.353853 4.36943C0.131326 4.5193 -2.08308e-07 4.76552 -2.1985e-07 5.02959C-2.31393e-07 5.29365 0.134975 5.53987 0.357501 5.68974L6.56635 9.84338C6.84724 10.0325 7.20839 10.0503 7.51117 9.89691C7.81031 9.74346 8 9.43658 8 9.10472Z" fill="#A4B3C5"/>
                    </svg>
                </div>
                <div>
                    <input v-number-only @change="changeCurrentPage" @input="correctCurrentPage" type="text" class="text-input _second current" v-model="paginator.current_page">
                    <span>из {{ maxPages }}</span>
                </div>
                <div class="table-paginator-arrow _right" :class="{hide: paginator.current_page == '' || paginator.current_page >= maxPages}" @click="nextPage()">
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 9.10472L8 0.89379C8 0.561927 7.81031 0.255043 7.50752 0.101601C7.20474 -0.051841 6.83995 -0.0304305 6.5627 0.158696L0.353853 4.36943C0.131326 4.5193 -2.08308e-07 4.76552 -2.1985e-07 5.02959C-2.31393e-07 5.29365 0.134975 5.53987 0.357501 5.68974L6.56635 9.84338C6.84724 10.0325 7.20839 10.0503 7.51117 9.89691C7.81031 9.74346 8 9.43658 8 9.10472Z" fill="#A4B3C5"/>
                    </svg>

                </div>
            </div>
            <div class="per-page inline free" valign="center">
                Показывать по
                <Select class="_second" :class="{top: perPageAlign == 'top'}" :data.sync="paginator.per_page" @select="selectPerPage()"></Select>
            </div>
        </div>
    </div>
</template>

<script>
import Select from '@/components/_Select.vue'

export default {
    props: ['data', 'schema', 'perPageAlign', 'defaultSort', 'defaultDirection', 'selectedRadio', 'tableName'],
    components: {
        Select
    },
    data () {
        return {
            selectRadio: false,
            paginator: {
                current_page: 1,
                per_page: {
                    value: 10,
                    open: false,
                    list: [10, 20, 30]
                }
            },
            sort: {
                name: this.defaultSort ? this.defaultSort : null,
                direction: this.defaultDirection ? this.defaultDirection : 'DESC'
            },
            renderItems: []
        }
    },
    computed: {
        selectedAll () {
            let selected = 0
            for (let item of this.data.list) {
                if (item.checked) selected++
            }
            if (selected == this.data.list.length) return true
            return false
        },
        maxPages () {
            return Math.ceil(this.data.count / this.paginator.per_page.value)
        }
    },
    watch: {
        selectedRadio () {
            if (!this.selectedRadio) this.selectRadio = false
        }
    },
    methods: {
        selectRadioItem (invoice) {
            this.$emit('selectRadio', invoice)
        },
        changeListItem (item, checked) {
            let itemChecked = checked || item.checked
            if (this.tableName == 'qualityDocPkgs') {
                if (itemChecked) {
                    item.invoiceChecked = item.existsInvoice
                    item.torg12Checked = item.existsTorg12
                    item.protocolChecked = item.existsProtocol
                } else {
                    item.invoiceChecked = false
                    item.torg12Checked = false
                    item.protocolChecked = false
                }
            } else {
                if (itemChecked) {
                    if (!this.getCheckboxIsDefect('passportCount', item.seriesNumber, item)) {
                        item.passportChecked = true
                    } else {
                        item.passportChecked = false
                    }
                    if (!this.getCheckboxIsDefect('certCount', item.seriesNumber, item)) {
                        item.certChecked = true
                    } else {
                        item.certChecked = false
                    }
                } else {
                    item.passportChecked = false
                    item.certChecked = false
                }
            }
        },
        toggleCheckboxStatus (type, item) {
            let name = false
            if (type == 'passportCount') {
                name = 'passportChecked'
            } else if (type == 'certCount') {
                name = 'certChecked'
            } else if (type == 'existsInvoice') {
                name = 'invoiceChecked'
            } else if (type == 'existsTorg12') {
                name = 'torg12Checked'
            } else if (type == 'existsProtocol') {
                name = 'protocolChecked'
            }            
            item[name] = !item[name]

            if (item[name]) {
                item.checked = true
            } else {
                if (this.tableName == 'qualityDocPkgs') {
                    if (!item.invoiceChecked && !item.torg12Checked && !item.protocolChecked) item.checked = false
                } else {
                    if (!item.passportChecked && !item.certChecked) item.checked = false
                }
            }
        },
        selectSeries (type, item) {
            for (let listItem of this.data.list) {
                if (listItem.id == item.id) {
                    listItem.seriesNumber = item[type].value
                    break
                }
            }
            this.changeListItem(item, item.checked)
        },
        getCheckboxStatus (type, item) {
            let name = false
            if (type == 'passportCount') {
                name = 'passportChecked'
            } else if (type == 'certCount') {
                name = 'certChecked'
            } else if (type == 'existsInvoice') {
                name = 'invoiceChecked'
            } else if (type == 'existsTorg12') {
                name = 'torg12Checked'
            } else if (type == 'existsProtocol') {
                name = 'protocolChecked'
            }
            return item[name]
        },
        getCheckboxIsDefect (type, activeNumber, item) {
            for (let serie of item.series) {
                if (serie.seriesNumber == activeNumber) {
                    if (serie.isDefect || serie[type] == 0) return true
                }
            }

            return false
        },
        changeSelectAll (e) {
            for (let item of this.data.list) {
                item.checked = e.target.checked
                this.changeListItem(item, e.target.checked)
            }
        },
        resetPaginator () {
            this.paginator.current_page = 1
            this.paginator.per_page.value = 10
        },
        selectPerPage() {
            this.paginator.current_page = 1
            this.getData()
        },
        prevPage() {
            this.paginator.current_page--
            this.getData()
        },
        nextPage() {
            this.paginator.current_page++
            this.getData()
        },
        correctCurrentPage () {
            if (typeof this.paginator.current_page == 'string') {
                this.paginator.current_page = this.paginator.current_page.replace(/[^0-9]+/g, '');
            }
            if (this.paginator.current_page > this.maxPages) this.paginator.current_page = this.maxPages
        },
        changeCurrentPage() {
            if (typeof this.paginator.current_page == 'string') {
                this.paginator.current_page = this.paginator.current_page.replace(/[^0-9]+/g, '');
            }
            if (this.paginator.current_page == '' || this.paginator.current_page == 0) this.paginator.current_page = 1
            if (this.paginator.current_page > this.maxPages) this.paginator.current_page = this.maxPages
            this.getData()
        },
        toggleSort(name) {
            if (this.sort.name == name) {
                this.sort.direction == 'DESC' ? this.sort.direction = 'ASC' : this.sort.direction = 'DESC'
            } else {
                this.sort.name = name
                this.sort.direction = 'DESC'
            }
            this.getData()
        },
        getData () {
            let sendData = {
                paginator: this.paginator,
                sort: this.sort
            }
            this.$emit('getData', sendData)
        }
    }
}
</script>