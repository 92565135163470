<template>
    <div>
        <strong class="loading" v-if="status == 'loading'">Загрузка</strong>
        <div v-else>
            <BoomerangActive v-if="isActive"></BoomerangActive>
            <BoomerangNotActive v-else></BoomerangNotActive>
        </div>
    </div>
</template>

<script>
import BoomerangActive from '@/components/Boomerang/Active.vue'
import BoomerangNotActive from '@/components/Boomerang/NotActive.vue'

export default {
    metaInfo: {
        title: 'Программа «‎Бумеранг»'
    },
    data () {
        return {
            status: '',
            isActive: true
        }
    },
    components: {
        BoomerangActive,
        BoomerangNotActive
    },
    computed: {
          clientId () {
              return this.$store.state.common.client.id
          }
    },
    watch: {
        clientId () {
            this.getActivity()
        }
    },
    methods: {
        getActivity () {
            this.status = 'loading'
            this.axios({url: 'api/v1/boomerang/getboomerangactivity?clientId=' + this.$store.state.common.client.id, method: 'GET'})
                .then(resp => {
                    this.status = ''
                    this.isActive = resp.data.isActive
                }).catch(() => {
                    this.status = ''
                })
        }
    },
    mounted () {
        this.getActivity()
    }
}
</script>