<template>
    <div class="popup mobile-popup" type="MessageSend" size="small">
        <div class="popup-header">
            <h1>Ваше сообщение отправлено</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <p>Ваше сообщение успешно отправлено! Наши менеджеры свяжутся с{{'\xa0'}}вами в{{'\xa0'}}ближайшее время.<br/>
                    Благодарим за&nbsp;обращения в{{'\xa0'}}нашу компанию!</p>
            </div>

            <div class="mobile-bottom inline">
                <div class="button" type="decline" @click="$emit('closePopup')">Окей</div>
            </div>
        </Component>
    </div>
</template>

<script>
import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

export default {
    props: ['contentScroll'],
    components: {
        SmoothScrollbar
    }
}
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_MessageSend";
</style>