<template>
    <div>
        <div class="switcher-group column">
            <div class="button popup-switcher" type="search" @click="showMobilePopup('filter')">Найти</div>
            <div class="button popup-switcher disabled" type="letter" @click="showMobilePopup('report')">Подписаться на отчёт</div>
        </div>

        <div class="filter mobile-popup" :class="{_show: mobile_popup == 'filter'}">
            <div class="popup-header phone-only">
                <h1>Найти</h1>
                <div class="close" @click="hideMobilePopup()"></div>
            </div>

            <div class="inline" halign="between">
                <div class="filter-left inline" valign="center">
                    <div class="range">
                        <Datepicker placeholder="от" :value="filter.date_from" @dateUpdate="dateUpdateFrom"></Datepicker>
                        <span>&mdash;</span>
                        <Datepicker placeholder="до" :value="filter.date_to" @dateUpdate="dateUpdateTo"></Datepicker>
                    </div>
                    <div class="checkbox">
                        <input id="binding" type="checkbox" v-model="filter.binding" />
                        <label for="binding">Связанные юр. лица</label>
                    </div>
                </div>
                <div class="inline mobile-bottom" halign="right" valign="bottom">
                    <div class="button" type="confirm" @click="getProductsAndReset()">Найти</div>
                    <div class="button" type="download" :class="{disabled: !products.list.length}" @click="getProductsExcel()"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.92055 13.0849C6.87781 13.1451 6.82947 13.2025 6.77553 13.2565C6.25574 13.7763 5.413 13.7763 4.89321 13.2565L0.389842 8.7531C-0.129947 8.23331 -0.129948 7.39056 0.389842 6.87078C0.909632 6.35099 1.75238 6.35099 2.27217 6.87078L4.54756 9.14617L4.54756 1.331C4.54756 0.59591 5.14347 0 5.87856 0C6.61366 0 7.20957 0.595911 7.20957 1.331L7.20957 9.03128L9.33598 6.90487C9.85577 6.38508 10.6985 6.38507 11.2183 6.90486C11.7381 7.42465 11.7381 8.2674 11.2183 8.78719L6.92055 13.0849Z" fill="#8AC148"></path></svg>Скачать выбранные в Excel</div>
                </div>
            </div>
        </div>

        <div class="inline">
            <div class="summary column">
                <div v-if="totalWithVat" class="inline" valign="center" halign="between">
                    <p>Сумма с{{'\xa0'}}НДС</p>
                    <strong>{{ totalWithVat | price }}</strong>
                </div>
                <div v-if="total" class="inline" valign="center" halign="between">
                    <p>Сумма КН</p>
                    <strong>{{ total | price }}</strong>
                </div>
            </div>
            <div class="report mobile-popup disabled" :class="{_show: mobile_popup == 'report'}">
                <div class="popup-header inline" v-if="$store.state.common.isPhone">
                    <h1>Регулярный отчёт</h1>
                    <div class="close" @click="hideMobilePopup()"></div>
                </div>

                <div class="inline" halign="between" v-else>
                    <h3>Регулярный отчёт</h3>
                    <span>в разработке</span>
                </div>


                <div class="inline" valign="center">
                    <div class="form-item">
                        <input id="bmemail" type="text" class="text-input" required />
                        <label for="bmemail">Email</label>
                    </div>
                    <div class="form-item">
                        <Select id="bmperiod" size="normal" :data.sync="periodicity"></Select>
                        <label for="bmperiod">Периодичность</label>
                    </div>
                    <div class="mobile-bottom">
                        <div class="button" type="confirm">Отправить</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="table">
            <strong class="loading" v-if="products.status == 'loading' && !products.list.length">Загрузка продуктов</strong>
            <div v-else>
                <div v-if="!products.list.length">
                    <strong class="no-items">Продукты не найдены</strong>
                </div>

                <Table v-else
                       ref="dataTable"
                       @getData="getTableData"
                       :data.sync="products"
                       defaultSort="name"
                       defaultDirection="ASC"
                       :schema="[
                                    {id: 'name', name: 'Наименование'},
                                    {id: 'manufacturer', name: 'Производитель'},
                                    {id: 'count', name: 'Кол-во', align: 'right'},
                                    {id: 'sumWithVat', name: 'Сумма с НДС', align: 'right'},
                                    {id: 'boomerangPercent', name: 'Процент', align: 'center'},
                                    {id: 'boomerangSum', name: 'Сумма КН', align: 'right'},
                                    {id: 'invoiceNumber', name: '№ с/ф'},
                                    {id: 'invoiceDate', name: 'Дата с/ф', align: 'right'}
                               ]">
                </Table>
            </div>
        </div>
        <div class="button phone-only" type="download" :class="{disabled: !products.list.length}" @click="getProductsExcel()">
            <img src="@/assets/images/download.svg" />
            Скачать таблицу в Excel</div>

    </div>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import Select from '@/components/_Select.vue'
import Table from '@/components/Table.vue'
import FileSaver from 'file-saver'

export default {
    data () {
        return {
            status: '',
            mobile_popup: false,
            filter: {
                date_from: '',
                date_to: '',
                binding: true
            },
            periodicity: {
                open: false,
                value: '',
                list: []
            },
            products: {
                status: '',
                list: [],
                count: null
            },
            total: false,
            totalWithVat: false,
            cached: {
                paginator: false,
                sort: false
            }
        }
    },
    components: {
        Datepicker,
        Select,
        Table
    },
    computed: {
        clientId () {
            return this.$store.state.common.client.id
        }
    },
    watch: {
        clientId () {
            this.getProducts()
        }
    },
    methods: {
        getProductsAndReset () {
            this.getProducts()
            if (this.$refs.dataTable) this.$refs.dataTable.resetPaginator()
        },
        getTableData (e) {
            this.getProducts(e.paginator, e.sort)
        },
        dateUpdateFrom (date) {
            this.filter.date_from = date
        },
        dateUpdateTo (date) {
            this.filter.date_to = date
        },
        showMobilePopup (type) {
            this.mobile_popup = type
            document.documentElement.style.overflow = 'hidden'
        },
        hideMobilePopup () {
            this.mobile_popup = false
            document.documentElement.style.overflow = 'auto'
        },
        getProducts (paginator = false, sort = false) {
            let sorting = sort ? sort : (this.$refs.dataTable && this.$refs.dataTable.sort ? this.$refs.dataTable.sort : false)
            this.products.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                page: paginator ? paginator.current_page : 1,
                itemsPerPage: paginator ? paginator.per_page.value : 10,
                sortBy: sorting ? sorting.name : 'name',
                sortDesc: sorting ? (sorting.direction == 'DESC' ? true : false) : false,
                allLinked: this.filter.binding,
                dateFrom: this.filter.date_from != '' ? this.filter.date_from : null,
                dateTo: this.filter.date_to != '' ? this.filter.date_to : null
            }
            this.axios({url: 'api/v1/boomerang/getall', data: data, method: 'POST'})
                .then(resp => {
                    this.total = resp.data.boomerangTotal
                    this.totalWithVat = resp.data.totalWithVat
                    this.products.status = ''
                    this.products.list = resp.data.items
                    this.products.count = resp.data.count

                    if (paginator) this.cached.paginator = paginator
                    if (sort) this.cached.sort = sort
                }).catch(() => {
                    this.products.status = ''
                })
        },
        getProductsExcel () {
            let paginator = this.cached.paginator,
                sort = this.cached.sort,
                data = {
                clientId: this.$store.state.common.client.id,
                page: paginator ? paginator.current_page : 1,
                itemsPerPage: paginator ? paginator.per_page.value : 10,
                sortBy: sort ? sort.name : 'name',
                sortDesc: sort ? (sort.direction == 'DESC' ? true : false) : false,
                allLinked: this.filter.binding,
                dateFrom: this.filter.date_from != '' ? this.filter.date_from : null,
                dateTo: this.filter.date_to != '' ? this.filter.date_to : null
            }
            this.axios({url: 'api/v1/boomerang/getreport', data: data, method: 'POST', responseType: 'blob'})
                .then(resp => {
                    const filename = 'boomerang_products.xlsx'

                    const blob = new Blob([resp.data], {
                        type: 'application/vnd. ms-excel'
                    })
                    FileSaver.saveAs(blob, filename)
                })
        }
    },
    mounted () {
        this.getProducts()
    }
}
</script>


<style lang="scss">
    @import "../../assets/styles/components/boomerangActive.scss";
</style>
