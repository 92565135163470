<template>
    <div class="page">
        <div class="page-content">
            <Header @openPopup="openPopup" @newLinesDownload="newLinesDownload" @faqDownload="faqDownload"></Header>

            <router-view v-if="$route.name == 'notFound'" />

            <div :class="$route.name" v-else>
                <Breadcrumbs :routeName="routeName" v-if="$route.name != 'main'"></Breadcrumbs>

                <Slider 
                        type="horizontal-top"
                        :data="banners[$route.name].HorizontalTop"
                        v-if="banners[$route.name].HorizontalTop && banners[$route.name].HorizontalTop.length">
                </Slider>

                <div class="content wrapper">
                    <h1 v-if="routeName != '/'">{{ routeName }}</h1>
                    <div class="inline mob-col content-aside">
                        <div class="content-container" :class="{fullWidth: noAside}">
                            <router-view v-if="$store.state.common.client.id"
                                         @openPopup="openPopup"
                                         @newLinesDownload="newLinesDownload" />
                        </div>
                        <Aside v-if="!noAside"
                               :topBanners="banners[$route.name].VerticalTop"
                               :bottomBanners="banners[$route.name].VerticalBottom">
                        </Aside>
                    </div>

                    <Slider disabled="true"
                            type="vertical-bottom"
                            :data="banners[$route.name].VerticalBottom"
                            v-if="banners[$route.name].VerticalBottom && $route.name == 'main' && !$store.state.common.isPhone">
                    </Slider>
                </div>
            </div>
        </div>
        <Footer @openPopup="openPopup" @newLinesDownload="newLinesDownload" @privacyPolicyDownload="privacyPolicyDownload"></Footer>
        <Popups v-if="activePopup.type"
                @openPopup="openPopup"
                @closePopup="closePopup"
                :activePopup="activePopup">
        </Popups>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Header from '@/components/Header.vue'
    import Breadcrumbs from '@/components/Breadcrumbs.vue'
    import Slider from '@/components/Slider.vue'
    import Aside from '@/components/Aside.vue'
    import Footer from '@/components/Footer.vue'
    import Popups from '@/components/Popups.vue'
    import FileSaver from 'file-saver'

    export default {
        components: {
            Header,
            Breadcrumbs,
            Slider,
            Aside,
            Footer,
            Popups
        },
        data () {
            return {
                activePopup: false,
                banners: {
                    main: {},
                    promotions: {},
                    claims: {},
                    receivables: {},
                    boomerang: {},
                    quality: {}
                },
                managers: {
                    status: '',
                    list: []
                }
            }
        },
        computed: {
            routeName () {
                switch (this.$route.name) {
                    case 'promotions': return 'Акции'
                    case 'claims': return 'Претензии'
                    case 'receivables': return 'Дебиторская задолженность'
                    case 'boomerang': return 'Программа «‎Бумеранг»'
                    case 'quality': return 'Документы'
                    default: return '/'
                }
            },
            isBlankPage () {
                return this.$route.name == 'login'
            },
            noAside () {
                if (this.$route.name == 'promotions' || this.$route.name == 'claims') {
                    return true
                }
                return false
            },
            clientId () {
                return this.$store.state.common.client.id
            }
        },
        watch: {
            clientId () {
                this.getManagers()
            }
        },
        methods: {
            newLinesDownload (type) {
                let ext, mime, url = 'api/v1/files/getnewlines' + type

                if (type == 'excel') {
                    ext = '.xlsx'
                    mime = 'vnd. ms-excel'
                } else if (type == 'pdf') {
                    ext = '.pdf'
                    mime = 'pdf'
                }

                this.axios({url: url, method: 'GET', responseType: 'blob'})
                    .then(resp => {
                        const filename = 'Новые строки прайса' + ext

                        const blob = new Blob([resp.data], {
                            type: 'application/' + mime
                        })
                        FileSaver.saveAs(blob, filename)
                    })
            },
            privacyPolicyDownload () {
                let ext = '.docx', url = 'api/v1/files/getprivacypolicy'

                this.axios({url: url, method: 'GET', responseType: 'blob'})
                    .then(resp => {
                        const filename = 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ООО «АГРОРЕСУРСЫ»' + ext

                        const blob = new Blob([resp.data], {
                            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        })
                        FileSaver.saveAs(blob, filename)
                    })
            },
            faqDownload () {
                let ext = '.docx', url = 'api/v1/files/getfaq'

                this.axios({url: url, method: 'GET', responseType: 'blob'})
                    .then(resp => {
                        const filename = 'ПОЛЬЗОВАТЕЛЬСКАЯ ИНСТРУКЦИЯ' + ext

                        const blob = new Blob([resp.data], {
                            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                        })
                        FileSaver.saveAs(blob, filename)
                    })
            },
            openPopup (data) {
                if (typeof data == 'string') {
                    this.activePopup = {
                        type: data
                    }
                } else {
                    this.activePopup = data
                }
            },
            closePopup (flag = false) {
                if (this.activePopup.type == 'PollStart' || this.activePopup.type == 'PollDone') {
                    if (!flag) this.postponePoll(this.activePopup.pollId)
                }
                this.activePopup = false
            },
            getPageNameByBanner (pageName) {
                switch (pageName) {
                    case 'Actions': return 'promotions'
                    case 'Receivable': return 'receivables'
                    case 'QualityDocs': return 'quality'
                    default: return pageName.toLowerCase()
                }
            },
            splitBanners (data) {
                for (let banners of data) {
                    let realPageName = this.getPageNameByBanner(banners.value)
                    for (let banner of banners.banners) {
                        if (typeof this.banners[realPageName][banner.type] == 'undefined') {
                            Vue.set(this.banners[realPageName], banner.type, [])
                        }
                        this.banners[realPageName][banner.type].push(banner)
                    }
                }
            },
            getBanners () {
                this.axios({url: 'api/v1/banners/getpages', method: 'GET'})
                    .then(resp => this.splitBanners(resp.data))
            },
            getClient () {
                return this.axios({url: 'api/v1/user/getclient', method: 'GET'})
                    .then(resp => {
                        this.$store.commit('common/SET_CLIENT', resp.data)
                    }).catch(() => {
                })
            },
            getAllClients () {
                let getClientsUrl = "api/v1/user/getallclientsbycurrentholding";
				return this.axios({ url: getClientsUrl, method: "GET" });
            },
            getActivePoll () {
                this.axios({url: 'api/v1/polls/getactivepoll', method: 'GET'})
                    .then(resp => {
                        if (resp.data.pollId) {
                            this.openPopup({
                                type: 'PollStart',
                                pollId: resp.data.pollId,
                                questions: resp.data.questions
                            })
                        }
                    })
            },
            postponePoll (id) {
                let data = {id: id}
                this.axios({url: 'api/v1/polls/postponepoll', data: data, method: 'POST'})
            },
            getManagerTypes () {
                this.axios({url: 'api/v1/user/getmanagertypes', method: 'GET'})
                    .then(resp => {
                        this.$store.commit('common/SET_MANAGER_TYPES', resp.data)
                    })
            },
            getManagers () {
                this.axios({url: 'api/v1/user/getmanagers?clientId=' + this.$store.state.common.client.id, method: 'GET'})
                    .then(resp => {
                        this.$store.commit('common/SET_MANAGERS', resp.data)
                    })
            }
        },
        async mounted () {
            document.getElementById('app').style.overflowY = 'scroll'

            this.getBanners()

            if (this.$store.state.common.authType == 'form') {
                this.getActivePoll()
            }

            const selectedPoint = localStorage.getItem('selectedPoint')
            if (selectedPoint) {
                let found = false
                await this.getAllClients().then(resp => {
                    for (let client of resp.data) {
                        if (client.id == selectedPoint) {
                            this.$store.commit('common/SET_CLIENT', client)
                            found = true
                            break
                        }
                    }
                })
                if (!found) {
                    localStorage.removeItem('selectedPoint')
                    await this.getClient()
                }
            } else {
                await this.getClient()
            }

            this.getManagerTypes()
            this.getManagers()
        }
    }
</script>