
<template>
  <div id="app">
      <Component :is="layout"></Component>
  </div>
</template>

<script>
import LoginLayout from '@/layouts/Login.vue'
import DefaultLayout from '@/layouts/Default.vue'

export default {
    components: {
        LoginLayout,
        DefaultLayout
    },
    created: function () {
        let self = this
        this.axios.interceptors.response.use(undefined, function (err) {
            return new Promise(() => {
                if (err.response.status === 404) {
                    if (self.$store.getters['auth/isLoggedIn']) {
                        self.$store.dispatch('auth/logout')
                    }
                } else if (err.response.status === 401) {
                    localStorage.removeItem('token')
                    delete self.axios.defaults.headers.common['Authorization']
                    self.$store.commit('auth/RESET')         
                    self.$router.go()
                }

                throw err
            })
        })

        const token = localStorage.getItem('token')
        if (token) {
            let data = { accessToken: JSON.parse(token) }
            this.$store.commit('auth/SUCCESS', data)
            this.$store.dispatch('common/loadUserData')
        }

       
    },
    methods: {
        setVh () {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        },
        onResize () {
            this.setVh()
            this.setIsPhone()
        },
        setIsPhone () {
            let isPhone = window.innerWidth <= 768 ? true : false
            this.$store.commit('common/SET_IS_PHONE', isPhone)
        },
    },
    computed: {
        layout () {
            if (this.$route.name == 'login') return 'LoginLayout'
            else return 'DefaultLayout'
        }
    },
    mounted () {
        this.onResize()
        window.addEventListener('resize', this.onResize);
    }
}
</script>

<style lang="scss">
  @import "./assets/styles/common/reset.css";
  @import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

  @import "./assets/styles/common/base.scss";
  @import "./assets/styles/common/grid.scss";
  @import "./assets/styles/common/parts.scss";
  @import "./assets/styles/common/buttons.scss";
  @import "./assets/styles/common/form.scss";
  @import "./assets/styles/common/phone.scss";
</style>