<template>
    <div class="popup mobile-popup message-form" type="SendToManager" size="medium" :status="status">
        <div class="popup-header">
            <h1>Написать менеджеру</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <div class="to-manager column">
                    <strong>{{ activePopup.managerName }}</strong>
                    <a :href="'mailto:'+activePopup.managerEmail">{{ activePopup.managerEmail }}</a>
                </div>

                <div class="inline mob-col" halign="between" valign="top">
                    <div class="column">
                        <div class="form-item no-label" :class="{_error: show_error && firstName == ''}">
                            <input id="smfname" type="text" class="text-input" v-model.trim="firstName" required/>
                            <label for="smfname">Имя*</label>
                        </div>
                        <div class="form-item no-label" :class="{_error: show_error && lastName == ''}">
                            <input id="smlname" type="text" class="text-input" v-model.trim="lastName" required/>
                            <label for="smlname">Фамилия*</label>
                        </div>
                        <div class="form-item no-label">
                            <input id="smmname" type="text" class="text-input" v-model.trim="middleName" required/>
                            <label for="smmname">Отчество</label>
                        </div>
                    </div>
                    <div class="column _short">
                        <div class="form-item" size="small" :class="{_error: show_error && phone.length < 12}">
                            <input id="smphone" type="text" class="text-input" v-model.trim="phone" v-mask="'+7##########'" required/>
                            <label for="smphone">Ваш номер телефона*</label>
                        </div>
                        <div class="form-item" :class="{_error: show_error && !emailIsValid(email)}">
                            <input id="smemail" type="text" class="text-input" v-model.trim="email" required/>
                            <label for="smemail">Ваш email*</label>
                        </div>
                    </div>
                </div>

                <div class="inline form-item">
                    <textarea id="smmsg" v-model.trim="comment" :class="{_error: show_error && comment == ''}" required></textarea>
                    <label for="smmsg">Комментарий*</label>
                </div>
            </div>

            <div class="mobile-bottom inline" halign="right">
                <div class="button" type="confirm" @click="sendMessage()">Отправить</div>
            </div>
        </Component>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'
    import {mask} from 'vue-the-mask'

    export default {
        props: ['activePopup', 'contentScroll'],
        directives: {mask},
        components: {
            SmoothScrollbar
        },
        data () {
            return {
                status: '',
                firstName: '',
                lastName: '',
                middleName: '',
                phone: '',
                email: '',
                comment: '',
                show_error: false,
                show_error_tm: false,
                error_msg: ''
            }
        },
        computed: {
            formIsValid () {
                if (this.firstName == '' || this.lastName == '' || this.comment == '' || !this.emailIsValid(this.email) || this.phone.length < 12) return false
                return true
            }
        },
        methods: {
            emailIsValid (email) {
                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
            },
            showError (msg = '') {
                this.error_msg = msg
                this.show_error = true
                if (this.show_error_tm) clearTimeout(this.show_error_tm)
                this.show_error_tm = setTimeout(() => {
                    this.show_error = false
                    this.error_msg = ''
                }, 5000)
            },
            sendMessage () {
                if (!this.formIsValid) {
                    this.showError()
                    return false
                }
                this.show_error = false
                this.status = 'loading'

                let data = {
                    clientId: this.$store.state.common.client.id,
                    managerGuid: this.activePopup.managerGuid,
                    managerEmail: this.activePopup.managerEmail,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    middleName: this.middleName,
                    email: this.email,
                    phone: this.phone,
                    comment: this.comment
                }
                this.axios({url: '/api/v1/user/sendmessagetomanager', data: data, method: 'POST'})
                    .then(() => {
                        this.status = ''
                        this.$emit('openPopup', 'MessageSend')
                    }).catch(err => {
                        if (err.response && err.response.data.Message) {
                            this.showError(err.response.data.Message)
                        }
                        this.status = ''
                    })
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_MessageForm";
    @import "../../assets/styles/components/popup_SendToManager";
</style>