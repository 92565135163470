<template>
    <div class="slider" v-if="slides.length">
        <swiper :options="options" ref="mySwiper" @slideChange="onSlideChange" :class="{'swiper-no-swiping': disabled}">
            <swiper-slide v-for="(item, key) in slides" :key="key" >
                <a v-if="item.link" :href="item.link" target="_blank" class="rounded" :class="{wrapper: !disabled}" v-on:click="itemClick">
                    <img :src="item.img"/>
                </a>
                <span v-else class="rounded" :class="{wrapper: !disabled}">
                    <img :src="item.img"/>
                </span>
            </swiper-slide>
        </swiper>

        <div class="slider-pagination" v-if="!disabled">
            <div v-for="(item, key) in slides" :key="key" :class="{'active': active == key}" @click="slideTo(key)"></div>
        </div>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    // import 'swiper/swiper-bundle.css'
    import 'swiper/css/swiper.css'

    export default {
        props: ['data', 'disabled', 'type'],
        components: {
            Swiper,
            SwiperSlide
        },
        computed: {
            swiper() {
                if (this.slides.length) {
                    return this.$refs.mySwiper.$swiper
                }
                return false
            },
            slides() {
                let result = []
                for (let slide of this.data) {
                    let image = this.$store.state.common.isPhone ? slide.mobileImageUrl : slide.imageUrl

                    if (!image) continue

                    result.push({
                        link: slide.link,
                        img: process.env.VUE_APP_ROOT_API + '/api/v1' + image
                    })
                }
                return result
            }
        },
        data () {
            return {
                active: 0,
                options: {
                    grabCursor: true,
                    autoHeight: true
                },
                slideTm: false
            }
        },
        methods: {
            geSlideDataIndex () {
                let activeIndex = this.swiper.activeIndex;
                let slidesLen = this.swiper.slides.length;
                if(this.swiper.params.loop){
                    switch(this.swiper.activeIndex){
                        case 0:
                            activeIndex = slidesLen-3;
                            break;
                        case slidesLen-1:
                            activeIndex = 0;
                            break;
                        default:
                            --activeIndex;
                    }
                }
                return  activeIndex;
            },
            slideTo (id) {
                let index = this.geSlideDataIndex(id)
                this.swiper.slideTo(index)
            },
            onSlideChange() {
                this.active = this.swiper.realIndex
                this.setSlideTimeout()
            },
            setSlideTimeout () {
                let activeSlide = this.data[this.swiper.realIndex]
                if (this.slideTm) clearTimeout(this.slideTm)
                this.slideTm = setTimeout(() => {
                    this.swiper.slideNext()
                }, activeSlide.frequency * 1000)
            },
            itemClick() {
              try {
                switch (this.type) {
                  case "vertical-top":
                    window.dataLayer.push({ event: "gtm_banervert1_send_click" });
                    break;
                  case "vertical-bottom":
                    window.dataLayer.push({ event: "gtm_banervert2_send_click" });
                    break;
                  case "horizontal-top":
                    window.dataLayer.push({ event: "gtm_banergor_send_click" });
                    break;
                  case "horizontal-bottom":
                    window.dataLayer.push({ event: "gtm_banergor_send_click" });
                    break;
                }
              } catch (e) {
                console.log(e);
              }
            },
        },
        created () {
            if (this.data.length > 1) {
                this.options.loop = true
            }
        },
        mounted () {
            if (this.slides.length) {
                this.setSlideTimeout()
            }
        },
        beforeDestroy () {
            if (this.slideTm) clearTimeout(this.slideTm)
        }
    };
</script>

<style lang="scss">
    @import "./../assets/styles/components/slider.scss";
</style>