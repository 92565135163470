<template>
    <div class="popup mobile-popup" type="Invoice" size="big" :status="status">
        <div class="popup-header">
            <h1><span>№ счёт-фактуры:</span> {{ this.activePopup.id }}</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">

                <div class="inline mob-col top-line" halign="between" valign="center">
                    <div class="inline mob-col">
                        <div class="form-item">
                            <input type="text" id="invoicename" class="text-input" v-model.trim="name" required />
                            <label for="invoicename">Наименование</label>
                        </div>
                        <div class="form-item">
                            <input type="text" id="invoiceman" class="text-input" v-model.trim="manufacturer" required />
                            <label for="invoiceman">Производитель</label>
                        </div>
                    </div>
                    <div class="button" type="confirm" :class="{disabled: products.status == 'loading'}" @click="getProductsAndReset()">Показать</div>
                    <div class="button" type="decline" :class="{disabled: products.status == 'loading'}" @click="resetFilter()">Очистить</div>
                </div>

                <div class="separator" v-if="!$store.state.common.isPhone"></div>

                <div class="inline" halign="right" v-if="!$store.state.common.isPhone">
                    <div @click="getReestrOfCerts()" class="button" type="download"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.92055 13.0849C6.87781 13.1451 6.82947 13.2025 6.77553 13.2565C6.25574 13.7763 5.413 13.7763 4.89321 13.2565L0.389842 8.7531C-0.129947 8.23331 -0.129948 7.39056 0.389842 6.87078C0.909632 6.35099 1.75238 6.35099 2.27217 6.87078L4.54756 9.14617L4.54756 1.331C4.54756 0.59591 5.14347 0 5.87856 0C6.61366 0 7.20957 0.595911 7.20957 1.331L7.20957 9.03128L9.33598 6.90487C9.85577 6.38508 10.6985 6.38507 11.2183 6.90486C11.7381 7.42465 11.7381 8.2674 11.2183 8.78719L6.92055 13.0849Z" fill="#8AC148"></path></svg>Скачать реестр сертификатов</div>
                    <div :class="{disabled: noOneSelected}" @click="getPassportsAndCerts()" class="button" type="download"><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.92055 13.0849C6.87781 13.1451 6.82947 13.2025 6.77553 13.2565C6.25574 13.7763 5.413 13.7763 4.89321 13.2565L0.389842 8.7531C-0.129947 8.23331 -0.129948 7.39056 0.389842 6.87078C0.909632 6.35099 1.75238 6.35099 2.27217 6.87078L4.54756 9.14617L4.54756 1.331C4.54756 0.59591 5.14347 0 5.87856 0C6.61366 0 7.20957 0.595911 7.20957 1.331L7.20957 9.03128L9.33598 6.90487C9.85577 6.38508 10.6985 6.38507 11.2183 6.90486C11.7381 7.42465 11.7381 8.2674 11.2183 8.78719L6.92055 13.0849Z" fill="#8AC148"></path></svg>Скачать выбранное</div>
                </div>

                <div class="table">
                    <strong class="loading" v-if="products.status == 'loading' && !products.list.length">Загрузка продуктов</strong>
                    <div v-else>
                        <div v-if="!products.list.length">
                            <strong class="no-items">Продукты не найдены</strong>
                        </div>

                        <Table v-else
                               ref="dataTable"
                               @getData="getTableData"
                               defaultSort="productName"
                               defaultDirection="ASC"
                               perPageAlign="top"
                               :data.sync="products"
                               :schema="[
                                    {id: 'productName', name: 'Наименование', checkbox: true},
                                    {id: 'manufacturer', name: 'Производитель'},
                                    {id: 'seriesList', name: 'Серия', noSort: true},
                                    {id: 'passportCount', name: 'Паспорт'},
                                    {id: 'certCount', name: 'Серт-т'}
                               ]">
                        </Table>
                    </div>
                </div>
            </div>
        </Component>

        <div class="phone-only column">
            <div class="button" type="download" @click="getReestrOfCerts()">
                <img src="@/assets/images/download.svg" />
                Скачать реестр сертификатов</div>
            <div class="button" type="download" :class="{disabled: noOneSelected}" @click="getPassportsAndCerts()">
                <img src="@/assets/images/download.svg" />
                Скачать выбранное</div>
        </div>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'
    import Table from '@/components/Table.vue'
    import FileSaver from 'file-saver'

    export default {
        props: ['activePopup', 'contentScroll'],
        data () {
            return {
                status: '',
                products: {
                    status: '',
                    list: [],
                    count: null
                },
                name: '',
                manufacturer: ''
            }
        },
        components: {
            SmoothScrollbar,
            Table
        },
        computed: {
            noOneSelected () {
                for (let item of this.products.list) {
                    if (item.checked && (item.passportChecked || item.certChecked)) {
                        return false
                    }
                }
                return true
            },
            clientId () {
                return this.$store.state.common.client.id
            }
        },
        watch: {
            clientId () {
                this.getProducts()
            }
        },
        methods: {
            resetFilter () {
                this.name = ''
                this.manufacturer = ''
                this.getProducts()
            },
            getReestrOfCerts () {
                this.status = 'loading'
                let data = {
                    orderCode: this.activePopup.orderCode,
                    clientId: this.$store.state.common.client.id,
                    productSeries: this.getCheckedProducts()
                }

                this.axios({url: 'api/v1/qualitydocs/getregisterofcerts', data: data, method: 'POST', responseType: 'blob'})
                    .then(resp => {
                        this.status = ''
                        const filename = 'reestr_of_certs.zip'
                        const blob = new Blob([resp.data], {
                            type: 'application/zip'
                        })
                        FileSaver.saveAs(blob, filename)
                    }).catch(() => {
                        this.status = ''
                    })
            },
            getPassportsAndCerts () {
                this.status = 'loading'
                let data = {
                    orderCode: this.activePopup.orderCode,
                    clientId: this.$store.state.common.client.id,
                    productSeries: this.getCheckedProducts()
                }
                this.axios({url: 'api/v1/qualitydocs/getpassportsandcerts', data: data, method: 'POST', responseType: 'blob'})
                    .then(resp => {
                        this.status = ''
                        const filename = 'passports_and_certs.zip'
                        const blob = new Blob([resp.data], {
                            type: 'application/zip'
                        })
                        FileSaver.saveAs(blob, filename)
                    }).catch(() => {
                        this.status = ''
                    })
            },
            getCheckedProducts () {
                let result = []
                for (let item of this.products.list) {
                    if (item.checked) {
                        for (let series of item.series) {
                            if (series.seriesNumber == item.seriesNumber) {
                                let product = {
                                    productCode: item.productCode,
                                    seriesNumber: item.seriesNumber,
                                    seriesGuid: series.seriesGuid,
                                    downloadPassport: item.passportChecked,
                                    downloadCertificate: item.certChecked
                                }
                                result.push(product)
                                break
                            }
                        }
                    }
                }
                if (result.length) return result
                return null
            },
            getProductsAndReset () {
                this.getProducts()
                if (this.$refs.dataTable) this.$refs.dataTable.resetPaginator()
            },
            getTableData (e) {
                this.getProducts(e.paginator, e.sort)
            },
            correctData (data) {
                let count = 0
                for (let item of data) {
                    item.checked = false
                    item.certChecked = false
                    item.passportChecked = false
                    item.id = count

                    item.seriesList = {
                        open: false,
                        value: item.seriesNumber,
                        list: this.setSeriesList(item.series)
                    }
                    count++
                }
                return data
            },
            setSeriesList (series) {
                let result = []
                for (let item of series) {
                    result.push(item.seriesNumber)
                }
                return result
            },
            getProducts (paginator = false, sort = false) {
                this.products.status = 'loading'
                let data = {
                    orderCode: this.activePopup.orderCode,
                    clientId: this.$store.state.common.client.id,
                    page: paginator ? paginator.current_page : 1,
                    itemsPerPage: paginator ? paginator.per_page.value : 10,
                    sortBy: sort ? sort.name : null,
                    sortDesc: sort ? (sort.direction == 'DESC' ? true : false) : null,
                    productName: this.name,
                    manufacturer: this.manufacturer
                }
                this.axios({url: 'api/v1/qualitydocs/getproducts', data: data, method: 'POST'})
                    .then(resp => {
                        this.products.status = ''
                        this.products.list = this.correctData(resp.data.data)
                        this.products.count = resp.data.count
                    }).catch(() => {
                        this.products.status = ''
                    })
            }
        },
        mounted () {
            this.getProducts()
        }
    }
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_Invoice.scss";
</style>