<template>
    <div class="popup mobile-popup" type="Poll" size="small">
        <div class="popup-header">
            <h1>Пройдите опрос</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <p>Ответьте, пожалуйста, на{{'\xa0'}}несколько вопросов. Ваше мнение очень важно для нас.<br/>
                    Вы{{'\xa0'}}можете отложить опрос и{{'\xa0'}}вернуться к{{'\xa0'}}нему при следующей авторизации в{{'\xa0'}}веб-кабинете.</p>
                <div class="question" v-for="(question, question_id) in activePopup.questions" :key="question_id">
                    <h1>Вопрос {{ question_id + 1 }}</h1>

                    <div>
                        <p>{{ question.questionText }}</p>

                        <div class="answers">
                            <div class="answer" v-for="(answer, answer_id) in question.answers" :key="answer_id">
                                <input v-if="question.questionType == 1" :id="'answer_'+question_id+'_'+answer_id" :name="'question_'+question_id" type="radio" v-bind:value="answer.answerId" v-model="question.answer"  />
                                <input v-else :id="'answer_'+question_id+'_'+answer_id" type="checkbox" v-model="answer.checked" />
                                <label :for="'answer_'+question_id+'_'+answer_id">{{ answer.answerText }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Component>

        <div class="mobile-bottom inline" halign="between">
            <div class="button" type="decline" @click="$emit('closePopup')">Отложить</div>
            <div class="button" type="confirm" :class="{disabled: noSelected}" @click="completePoll()">Отправить</div>
        </div>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

    export default {
        props: ['activePopup', 'contentScroll'],
        components: {
            SmoothScrollbar
        },
        data () {
            return {
                noSelected: true
            }
        },
        watch: {
            'activePopup.questions': {
                handler() {
                    this.checkAnswer();
                },
                deep: true
            }
        },  
        methods: {
            completePoll () {
                this.$emit('openPopup', {
                    type: 'PollDone',
                    pollId: this.activePopup.pollId,
                    questions: this.activePopup.questions
                })
            }, 
            checkAnswer() {
                const questions = this.activePopup.questions;
                let noSelected = false;
                for (let question of questions) {
                    if (question.questionType == 1) {
                        if (!question.answer) {
                            noSelected = true
                            break
                        }
                    } else {
                        let hasAnswerChecked = false;
                        for (let answer of question.answers) {
                            if (answer.checked) {
                                hasAnswerChecked = true
                                break
                            }
                        }
                        if (!hasAnswerChecked) {
                            noSelected = true
                        }
                    }
                }
                this.noSelected = noSelected
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_Poll";
</style>