<template>
    <div class="inline boomerangNotActive mob-col" valign="top">
        <div class="switcher-group">
            <div class="button popup-switcher" type="star" @click="showMobilePopup()">Стать участником</div>
        </div>

        <div class="boomerang-text">
            <p>Подробности программы уточняйте у менеджера отдела продаж.</p>
        </div>
        <div class="boomerang-request mobile-popup" :status="request.status" :class="{_show: mobile_popup}">
            <div v-if="!request.sended">
                <div class="popup-header" v-if="$store.state.common.isPhone">
                    <h1>Стать участником программы</h1>
                    <div class="close" @click="hideMobilePopup()"></div>
                </div>
                <h4 v-else>Стать участником программы</h4>
                <div class="form-item" :class="{_error: show_error && !emailIsValid(email)}">
                    <input id="bmemail" type="text" v-model.trim="email" class="text-input" required />
                    <label for="bmemail">Email</label>
                </div>
                <p class="error" :class="{_show: show_error && error_msg}">{{ error_msg }}</p>

                <div class="inline mobile-bottom" halign="right">
                    <div class="button" type="confirm" @click="sendRequest()">Оставить заявку</div>
                </div>
            </div>
            <div v-else>
                <div class="popup-header" v-if="$store.state.common.isPhone">
                    <h1>Ваша заявка на{{'\xa0'}}участие в{{'\xa0'}}программе &laquo;Бумеранг&raquo; отправлена.<br/><br/>
                        Спасибо!</h1>
                    <div class="close" @click="hideMobilePopup()"></div>
                </div>
                <h4 v-else>Ваша заявка на{{'\xa0'}}участие в{{'\xa0'}}программе &laquo;Бумеранг&raquo; отправлена.<br/><br/>
                    Спасибо!</h4>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                mobile_popup: false,
                request: {
                    status: '',
                    sended: false
                },
                email: '',
                show_error: false,
                show_error_tm: false,
                error_msg: ''
            }
        },
        computed: {
            formIsValid () {
                return this.emailIsValid(this.email)
            }
        },
        methods: {
            showMobilePopup () {
                this.mobile_popup = true
                document.documentElement.style.overflow = 'hidden'
            },
            hideMobilePopup () {
                this.mobile_popup = false
                document.documentElement.style.overflow = 'auto'
            },
            emailIsValid (email) {
                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
            },
            showError (msg = '') {
                this.error_msg = msg
                this.show_error = true
                if (this.show_error_tm) clearTimeout(this.show_error_tm)
                this.show_error_tm = setTimeout(() => {
                    this.show_error = false
                    this.error_msg = ''
                }, 5000)
            },
            sendRequest () {
                if (!this.formIsValid) {
                    this.showError()
                    return false
                }
                this.show_error = false
                this.request.status = 'loading'
                this.axios({url: '/api/v1/boomerang/boomerangrequest?clientId=' + this.$store.state.common.client.id + '&email=' + this.email, method: 'GET'})
                    .then(() => {
                        this.request.status = ''
                        this.request.sended = true
                        this.email = ''
                    }).catch(err => {
                        this.request.status = ''
                        if (err.response && err.response.data.Message) {
                            this.showError(err.response.data.Message)
                        }
                    })
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/styles/components/boomerangNotActive.scss";
</style>
