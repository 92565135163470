<template>
    <div class="aside">
        <div class="aside-top">
            <Webinars></Webinars>

            <Slider disabled="true"
                    type="vertical-top"
                    :data="topBanners"
                    v-if="topBanners && topBanners.length">
            </Slider>
        </div>
        <Slider disabled="true"
                type="vertical-bottom"
                :data="bottomBanners"
                v-if="bottomBanners && bottomBanners.length && ($store.state.common.isPhone || $route.name != 'main')">
        </Slider>
    </div>
</template>

<style lang="scss">
    @import "./../assets/styles/components/aside.scss";
</style>

<script>
import Slider from '@/components/Slider.vue'
import Webinars from '@/components/Webinars.vue'

export default {
    props: ['topBanners', 'bottomBanners'],
    components: {
        Slider,
        Webinars
    }
}
</script>