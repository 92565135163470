import axios from 'axios'
import router from './../../router';

const state = {
    status: '',
    token: JSON.parse(localStorage.getItem('token')) || ''
}
const getters = {
    isLoggedIn (state) {
        return !!state.token
    },
    authStatus (state) {
        return state.status
    }
}
const actions = {
    logout ({ commit }) {
        commit('REQUEST')
        axios({url: 'api/v1/auth/logout', method: 'POST' })
            .then(() => {
                commit('RESET')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization']
                router.push('/login')
            })
    }
}
const mutations = {
    REQUEST (state) {
        state.status = 'loading'
    },
    SUCCESS (state, data) {
        state.status = ''
        state.token = data.accessToken
        localStorage.setItem('token', JSON.stringify(state.token))
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token
    },
    RESET (state) {
        state.status = ''
        state.token = ''
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}