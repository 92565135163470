<template>
    <div class="popup mobile-popup" type="RequestInvoice" size="mini" :status="status">
        <div class="popup-header">
            <h1>Запросить прайс-лист</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <p>Если вам не{{'\xa0'}}пришёл прайс-лист, вы{{'\xa0'}}можете запросить его повторно</p>
            </div>

            <div class="mobile-bottom inline" halign="right">
                <div class="button" type="confirm" @click="getPriceList()">Отправить</div>
            </div>
        </Component>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

    export default {
        props: ['contentScroll'],
        components: {
            SmoothScrollbar,
        },
        data () {
            return {
                status: ''
            }
        },
        methods: {
            getPriceList () {
                this.status = 'loading'
                this.axios({url: 'api/v1/price/generatepricesforclient?clientId=' + this.$store.state.common.client.id, method: 'GET'})
                    .then(resp => {
                        this.status = ''
                        this.$emit('openPopup', {
                            type: 'RequestSend',
                            message: resp.data.message
                        })
                    }).catch(() => {
                        this.status = ''
                    })
            }
        }
    }
</script>