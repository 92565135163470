<template>
    <div class="footer">
        <div class="wrapper">
            <div class="grid column" size="1/4">
                <strong>Документы</strong>
                <router-link class="link _reverse" to="/quality?tab=qualityDocPkgs">Пакеты документов</router-link>
                <router-link class="link _reverse" to="/quality?tab=quality">Документы качества</router-link>
                <strong>Претензии</strong>
                <router-link class="link _reverse" to="/claims?tab=search">Претензии по{{'\xa0'}}товарам</router-link>
                <span class="link _reverse" @click.prevent="$emit('openPopup', 'ServiceClaim')">Претензии к{{'\xa0'}}сервису</span>
                <router-link class="link _reverse" to="/claims?tab=question">Письмо претензионисту</router-link>
            </div>

            <div class="grid column" size="1/4">
                <strong><span @click="$emit('openPopup', 'RequestInvoice')" class="link _reverse">Электронная накладная</span></strong>
                <strong>Прайс-лист</strong>
                <span class="link _reverse" @click.prevent="$emit('openPopup', 'RequestPriceList')">Запросить прайс-лист</span>
                <span>Скачать новые строки в{{'\xa0'}}<span @click="$emit('newLinesDownload', 'pdf')" class="link _reverse">PDF</span></span>
                <span>Скачать новые строки в{{'\xa0'}}<span @click="$emit('newLinesDownload', 'excel')" class="link _reverse">Excel</span></span>
                <strong v-if="isLegalEntity"><router-link class="link _reverse" to="/receivables">Дебиторская задолжность</router-link></strong>
            </div>

            <div class="grid column" size="1/4">
                <strong v-if="isLegalEntity"><router-link class="link _reverse" to="/boomerang">Программа &laquo;Бумеранг&raquo;</router-link></strong>
                <strong><router-link class="link _reverse" to="/promotions">Акции</router-link></strong>
                <strong><span @click="$emit('openPopup', 'Support')" class="link _reverse">Техническая поддержка</span></strong>
                <strong><span @click="$emit('openPopup', 'Managers')" class="link _reverse">Контакты менеджеров</span></strong>
            </div>

            <div class="grid column right" size="1/4">
                <span @click="$emit('privacyPolicyDownload')" class="link _clear _reverse">Политика конфиденциальности</span>
                <span>&copy;&nbsp;Компания &laquo;Агроресурсы&raquo;, 1992-2020</span>
                <div class="social inline" halign="right">
                    <a href="https://www.facebook.com/AgroresPharm/" target="_blank" class="social-item _fb">
                        <img src="@/assets/images/social/fb.svg" />
                    </a>
                    <a href="https://www.instagram.com/agrores_pharm/" target="_blank" class="social-item _ig">
                        <img src="@/assets/images/social/ig.svg" />
                    </a>
                    <a href="https://vk.com/agrores_pharm" target="_blank" class="social-item _vk">
                        <img src="@/assets/images/social/vk.svg" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        isLegalEntity () {
            return this.$store.state.common.userData.isLegalEntity
        }
    }
}
</script>

<style lang="scss">
    @import "./../assets/styles/components/footer.scss";
</style>