<template>
    <div class="webinars rounded" v-if="webinars.length">
        <strong class="loading" v-if="status == 'loading'">Загрузка вебинаров</strong>
        <div v-else>
            <div class="webinars-header inline" halign="between" valign="center">
                <strong>Вебинары для фармацевтов</strong>
            </div>

            <div>
                <a :href="getLinkUrl(item.itemURL)" target="_blank" class="webinar" v-for="(item, key) in webinars" :key="key">
                    <span>{{ item.date | formatDate }}<span v-for="(speaker, skey) in item.speakers" :key="skey"> &bull;{{'\xa0'}}{{ speaker.title }}</span></span>
                    <p>{{ item.title }}</p>
                </a>
            </div>
            <div class="link-to-all">
                <a :href="'https://webinar.agrores.ru/?utm_source=' + getHostName() + '&utm_medium=referral&utm_campaign=page_webcam_agrores_ru'" class="link" target="_blank">Все вебинары</a>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "./../assets/styles/components/webinars.scss";
</style>

<script>
export default {
    data () {
        return {
            webinars: [],
            status: ''
        }
    },
    methods: {
        getWebinars () {
            this.status = 'loading'
            this.axios({url: 'api/v1/webinar/getall', method: 'GET'})
                .then(resp => {
                    this.status = ''
                    this.webinars = resp.data
                })
        },
        getHostName () {
            return location.host
        },
        getLinkUrl (baseUrl) {
            let paths = baseUrl.split('/')
            let compaign = paths[paths.length - 1].split('.html')[0]
            return baseUrl + '?utm_source=' + this.getHostName() + '&utm_medium=referral&utm_campaign=' + compaign
        }
    },
    mounted () {
        this.getWebinars()
    }
}
</script>