<template>
    <div class="login">
        <div :loading="$store.getters['auth/authStatus']" class="login-popup mobile-popup">
            <img class="logo" src="@/assets/images/logo.png" />

            <h1>Вход</h1>

            <div class="form-item" :class="{_error: show_error && username == ''}">
                <input v-if="!isMobile" id="login" maxlength="50" type="text" readonly onfocus="this.removeAttribute('readonly')" class="text-input _big" v-model.trim="username" required />
                <input v-else id="login" maxlength="50" type="text" class="text-input _big" v-model.trim="username" required />
                <label for="login">
                    <span>Имя пользователя</span>
                    <span>Введите имя пользователя</span>
                </label>
            </div>
            <div class="form-item" :class="{_error: show_error && password == ''}">
                <input v-if="!isMobile" id="loginpass" type="password" readonly onfocus="this.removeAttribute('readonly')" maxlength="500" class="text-input _big" v-model.trim="password" required />
                <input v-else id="loginpass" type="password" maxlength="500" class="text-input _big" v-model.trim="password" required />
                <label for="loginpass">Пароль</label>
            </div>

            <p class="error" :class="{_show: show_error && error_msg}">{{ error_msg }}</p>

            <div class="inline login-controls" halign="right" valign="center">
                <!--<div class="checkbox">
                    <input id="remember" type="checkbox" v-model="remember" />
                    <label for="remember">Запомнить</label>
                </div>-->
                <div class="mobile-bottom">
                    <div class="button free" type="confirm" @click="login()">Войти</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { isMobile } from 'mobile-device-detect';

    export default {
        metaInfo: {
            title: 'Веб-кабинет: вход'
        },
        data () {
            return {
                username: '',
                password: '',
                remember: true,
                show_error: false,
                show_error_tm: false,
                error_msg: ''
            }
        },
        computed: {
            formIsValid () {
                if (this.username == '' || this.password == '') return false
                return true
            },
            isMobile () {
                return isMobile
            }
        },
        methods: {
            showError (msg = '') {
                this.error_msg = msg
                this.show_error = true
                if (this.show_error_tm) clearTimeout(this.show_error_tm)
                this.show_error_tm = setTimeout(() => {
                    this.show_error = false
                    this.error_msg = ''
                }, 5000)
            },
            login () {
                if (!this.formIsValid) {
                    this.showError()
                    return false
                }

                this.show_error = false
                this.$store.commit('auth/REQUEST')
                let sendData = {
                    login: this.username,
                    password: this.password
                }
                this.axios({url: 'api/v1/auth/authenticate', data: sendData, method: 'POST' })
                    .then(resp => {
                        this.$store.commit('auth/SUCCESS', resp.data)
                        this.$store.commit('common/SET_AUTH_TYPE', 'form')

                        this.axios({url: 'api/v1/user/getUserData', method: 'GET' })
                            .then(userResp => {
                                this.$store.commit('common/SET_USER_DATA', userResp.data)
                                this.$router.push('/')
                            }).catch(err => {
                                this.authErrorHandler(err)
                            })
                    }).catch(err => {
                        this.authErrorHandler(err)
                    })
            },
            authErrorHandler (err) {
                if (err.response && err.response.data.Message) {
                    this.showError(err.response.data.Message)
                }
                this.$store.commit('auth/RESET')
            }
        },
        
        mounted () {
            document.getElementById('app').style.overflowY = 'auto'
        }
    }
</script>

<style lang="scss">
    @import "./../assets/styles/views/login.scss";
</style>