<template>
    <datepicker :disabled-dates="disabledDates" :placeholder="placeholder" :value="data" :language="ru" @selected="change" :monday="true" :format="customFormatter"></datepicker>
</template>

<script>
    import datepicker from 'vuejs-datepicker';
    import {ru} from 'vuejs-datepicker/dist/locale'

    export default {
        props: ['value', 'placeholder', 'disabled', 'disabledBeforeDates'],
        components: {
            datepicker
        },
        data () {
            return {
                data: this.value.toString(),
                ru: ru
            }
        },
        watch: {
          value () {
              this.data = this.value.toString()
          }
        },
        computed: {
            disabledDates () {
                let dates = {}
                if (this.disabled) {
                    let max_day = false
                    if (this.moment().date() > 15) {
                        max_day = this.moment().subtract('1', 'month')
                    } else {
                        max_day = this.moment().subtract('2', 'month')
                    }

                    dates.from = max_day.endOf('month').toDate()
                }

                if (this.disabledBeforeDates) {
                    dates.to = this.moment(this.disabledBeforeDates).subtract(1, 'days').toDate();
                }

                return dates
            }
        },
        methods: {
            change (e) {
                let data = this.moment(e)
                this.data = data.toString()
                this.$emit('update:value', data)
                this.$emit('dateUpdate', data)
            },
            customFormatter(date) {
                return this.moment(date).format('DD.MM.YYYY');
            }
        },
        mounted () {
            if (this.disabled) {
                let date = false
                if (this.moment().date() > 15) {
                    date = this.moment(this.value).subtract('1', 'month')
                } else {
                    date = this.moment(this.value).subtract('2', 'month')
                }
                this.change(date.endOf('month').toDate())
            }
        }
    }
</script>