<template>
    <div>
        <div class="switcher-group">
            <div class="button popup-switcher" @click="showMobilePopup('search')" :class="{active: filter.active == 'search'}" type="search">Найти</div>
            <div class="button popup-switcher" @click="showMobilePopup('add')" :class="{active: filter.active == 'add'}" type="add">Добавить</div>
            <div class="button popup-switcher" @click="showMobilePopup('question')" :class="{active: filter.active == 'question'}" type="question">Задать вопрос</div>
        </div>

        <div class="filter mobile-popup" padding="low" type="search" v-if="filter.active == 'search'" :class="{_show: filter.active == 'search' && mobile_popup}">
            <div class="popup-header phone-only">
                <h1>Найти претензию</h1>
                <div class="close" @click="hideMobilePopup()"></div>
            </div>

            <strong class="loading" v-if="filter.status == 'loading'">Конфигурация фильтра</strong>

            <div v-else class="inline mob-col" valign="bottom">
                <div class="filter-left inline" valign="bottom" halign="start">
                    <div class="inline">
                        <div class="column">
                            <div class="inline" valign="bottom">
                                <div class="form-item" size="small">
                                    <input type="text" id="filternum" class="text-input" v-model.trim="filter.number" required />
                                    <label for="filternum">№ претензии</label>
                                </div>
                                <div class="form-item" size="small">
                                    <input id="invoice_number" type="text" class="text-input" v-model.trim="filter.invoice" required />
                                    <label for="invoice_number">№ накладной</label>
                                </div>
                            </div>
                            <div class="range">
                                <Datepicker placeholder="от" :value="filter.date_from" @dateUpdate="dateUpdateFrom"></Datepicker>
                                <span>&mdash;</span>
                                <Datepicker placeholder="до" :value="filter.date_to" @dateUpdate="dateUpdateTo"></Datepicker>
                            </div>
                        </div>
                        <div class="column">
                            <div class="form-item">
                                <Select size="normal" @hideSelects="filter.decision.open = false" :data.sync="filter.types"></Select>
                                <label>Тип претензии</label>
                            </div>

                            <div class="form-item">
                                <Select size="normal" @hideSelects="filter.types.open = false" :data.sync="filter.decision"></Select>
                                <label>Решение</label>
                            </div>
                        </div>
                    </div>
                    <div class="inline">
                        <div class="column" v-for="(group, key) in groupedStatuses" :key="key">
                            <div class="checkbox _center" v-for="(status, keys) in group" :key="keys">
                                <input :id="'st_' + status.id" type="checkbox" v-model="getStatusById(status.id).checked" />
                                <label :for="'st_' + status.id">{{ status.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column right bottom mobile-bottom">
                    <div class="button" type="decline" @click="resetFilter()">Очистить</div>
                    <div class="button" type="confirm" :class="{disabled: products.status == 'loading'}" @click="getClaimsAndReset()">Найти</div>
                </div>
            </div>
        </div>

        <ClaimFilterAdd v-if="filter.active == 'add'"
                        ref="addClaim"
                        :class="{_show: filter.active == 'add' && mobile_popup}"
                        :types="filter.types"
                        @getData="getClaims"
                        @openPopup="openPopup"
                        @hideMobilePopup="hideMobilePopup()">
        </ClaimFilterAdd>

        <ClaimFilterQuestion v-if="filter.active == 'question'"
                        ref="questionClaim"
                        :class="{_show: filter.active == 'question' && mobile_popup}"
                        @openPopup="openPopup"
                        @hideMobilePopup="hideMobilePopup()">
        </ClaimFilterQuestion>

        <div class="table">
            <strong class="loading" v-if="products.status == 'loading' && !products.list.length">Загрузка списка претензий</strong>
            <div v-else>
                <div v-if="!products.list.length">
                    <strong class="no-items">Претензии не найдены</strong>
                </div>

                <Table v-else
                        ref="dataTable"
                        @getData="getTableData"
                        @openPopup="openPopup"
                        defaultSort="claimNumber"
                        :data="products"
                        :schema="[
                        {id: 'claimNumber', name: '№ претензии'},
                        {id: 'typeName', name: 'Тип претензии'},
                        {id: 'extraActionName', name: 'Действие'},
                        {id: 'claimDate', name: 'Дата претензии', align: 'right'},
                        {id: 'invoiceNumber', name: '№ накладной'},
                        {id: 'statusName', name: 'Статус'},
                        {id: 'verdictName', name: 'Решение'},
                        {id: 'verdictComment', name: 'Комментарий к решению'}
                   ]">
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import Select from '@/components/_Select.vue'
import ClaimFilterAdd from '@/components/Claim/FilterAdd.vue'
import ClaimFilterQuestion from '@/components/Claim/FilterQuestion.vue'
import Table from '@/components/Table.vue'

export default {
    metaInfo: {
        title: 'Претензии'
    },
    data () {
        return {
            mobile_popup: false,
            filter: {
                status: '',
                active: 'search',
                number: '',
                invoice: '',
                types: {
                    open: false,
                    value: '',
                    text_value: null,
                    list: []
                },
                date_from: '',
                date_to: '',
                decision: {
                    open: false,
                    value: '',
                    text_value: null,
                    list: []
                },
                statuses: []
            },
            products: {
                status: '',
                list: [],
                count: null
            }
        }
    },
    computed: {
        groupedStatuses () {
            let count = 0, result = [], sub_arr = []
            for (let status of this.filter.statuses) {
                sub_arr.push({
                    name: status.name,
                    id: status.id
                })

                if (count % 2 == 1) {
                  result.push(sub_arr)
                  sub_arr = []
                }
                count++
            }
            return result
        },
        requestUpdateTable () {
            return this.$store.state.common.requestUpdateTable
        },
        clientId () {
            return this.$store.state.common.client.id
        },
        query () {
            return this.$route.query;
        }
    },
    watch: {
        requestUpdateTable (newCount) {
            if (newCount) {
                this.getClaimsAndReset()
                this.$store.commit('common/REQUEST_UPDATE_TABLE', false)
                this.mobile_popup = false
            }
        },
        clientId () {
            this.getClaims()
        },
        query () {
            this.initTab()
            document.getElementById('app').scrollTo(0,0)
        }
    },
    methods: {
        getClaimsAndReset () {
            this.getClaims()
            if (this.$refs.dataTable) this.$refs.dataTable.resetPaginator()
        },
        openPopup (data) {
            this.$emit('openPopup', data)
        },
        resetFilter () {
            this.$refs.dataTable.sort.name = 'claimNumber'
            this.$refs.dataTable.sort.direction = 'DESC'

            this.filter.date_from = ''
            this.filter.date_to = ''

            this.filter.number = ''
            this.filter.invoice = ''

            this.filter.types.value = ''
            this.filter.types.text_value = null

            this.filter.decision.value = ''
            this.filter.decision.text_value = null

            for (let status of this.filter.statuses) {
                status.checked = true
            }

            this.getClaims()

        },
        getTableData (e) {
            this.getClaims(e.paginator, e.sort)
        },
        getStatusById (id) {
            for (let status of this.filter.statuses) {
                if (status.id == id) {
                    return status
                }
            }
        },
        getSelectedStatuses () {
            let result = []
            for (let item of this.filter.statuses) {
                if (item.checked) {
                    result.push(item.id)
                }
            }
            return result
        },
        showMobilePopup (type) {
            this.filter.active = type
            this.mobile_popup = true
            document.documentElement.style.overflow = 'hidden'
        },
        hideMobilePopup () {
            this.mobile_popup = false
            document.documentElement.style.overflow = 'auto'
        },
        getClaimTypes () {
            return new Promise((resolve) => {
                this.axios({url: 'api/v1/claim/getclaimtypes', method: 'GET'})
                    .then(resp => {
                        this.filter.types.list = resp.data
                        resolve()
                    })
            })
        },

        dateUpdateFrom (date) {
            this.filter.date_from = date
        },
        dateUpdateTo (date) {
            this.filter.date_to = date
        },
        getClaimVerdicts () {
            return new Promise((resolve) => {
                this.axios({url: 'api/v1/claim/getclaimverdicts', method: 'GET'})
                    .then(resp => {
                        this.filter.decision.list = resp.data
                        resolve()
                    })
            })
        },
        getClaimStatuses () {
            return new Promise((resolve) => {
                this.axios({url: 'api/v1/claim/getclaimstatuses', method: 'GET'})
                    .then(resp => {
                        let statuses = []
                        for (let status of resp.data) {
                            let item = {
                                id: status.id,
                                name: status.value,
                                checked: true
                            }
                            statuses.push(item)
                        }
                        this.filter.statuses = statuses
                        resolve()
                    })
            })

        },

        getClaims (paginator = false, sort = false) {
            let sorting = sort ? sort : (this.$refs.dataTable && this.$refs.dataTable.sort ? this.$refs.dataTable.sort : false)
            this.products.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                page: paginator ? paginator.current_page : 1,
                itemsPerPage: paginator ? paginator.per_page.value : 10,
                sortBy: sorting ? sorting.name : 'claimNumber',
                sortDesc: sorting ? (sorting.direction == 'DESC' ? true : false) : true,
                claimNumber: this.filter.number ? this.filter.number : null,
                invoiceNumber: this.filter.invoice ? this.filter.invoice : null,
                types: this.filter.types.value ? [this.filter.types.value] : [],
                statuses: this.getSelectedStatuses(),
                verdicts: this.filter.decision.value ? [this.filter.decision.value] : [],
                dateFrom: this.filter.date_from != '' ? this.filter.date_from : null,
                dateTo: this.filter.date_to != '' ? this.filter.date_to : null
            }
            this.axios({url: 'api/v1/claim/getclaims', data: data, method: 'POST'})
                .then(resp => {
                    this.products.status = ''
                    this.products.list = resp.data.data
                    this.products.count = resp.data.count
                }).catch(() => {
                    this.products.status = ''
                })
        },
        initTab() {
            const tab = this.$route.query?.tab;
            if (tab)
            {
                this.showMobilePopup(tab);
                let query = Object.assign({}, this.$route.query);
                delete query.tab;
                this.$router.replace({ query });
            }
        }
    },
    components: {
        Datepicker,
        Select,
        ClaimFilterAdd,
        ClaimFilterQuestion,
        Table
    },
    async mounted () {
        this.initTab();
        this.filter.status = 'loading'

        await Promise.all([
            this.getClaimTypes(),
            this.getClaimVerdicts(),
            this.getClaimStatuses()
        ]).then(
            this.filter.status = ''
        )

        this.getClaims()
    }
}
</script>


<style lang="scss">
    @import "./../assets/styles/views/claims.scss";
</style>