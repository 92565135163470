import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Meta from 'vue-meta';
import vClickOutside from 'v-click-outside'
import moment from 'moment';
//import VueGtm from 'vue-gtm';

Vue.use(Meta);
Vue.use(vClickOutside)

Vue.filter('formatDate', value => {
    if (value) return moment(String(value)).format('DD.MM.YYYY')
});
Vue.filter('price', value => {
    if (value) return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }) + '\xa0₽'
});

/*
import VueYandexMetrika from 'vue-yandex-metrika'
let metrica_env
if (location.href.indexOf('https://prof.mygreenway.com') != -1) {
    metrica_env = 'production'
} else {
    metrica_env = 'development'
}

Vue.use(VueYandexMetrika, {
    id: 73743397,
    router: router,
    env: metrica_env,
    options: {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
    }
})
*/
Vue.prototype.moment = moment;
Vue.prototype.axios = axios
Vue.prototype.axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

Vue.config.productionTip = false

Vue.directive('numberOnly', {
    bind: function(el) {
        el.handler = function() {
            el.value = el.value.replace(/\D+/, '')
        }
        el.addEventListener('input', el.handler)
    },
    unbind: function(el) {
        el.removeEventListener('input', el.handler)
    }
})

// Vue.use(VueGtm, {
//     id: 'GTM-NJNNDPB', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
//     /*queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
//         gtm_auth:'AB7cDEf3GHIjkl-MnOP8qr',
//         gtm_preview:'env-4',
//         gtm_cookies_win:'x'
//     },*/
//     defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
//     enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//     debug: true, // Whether or not display console logs debugs (optional)
//     loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
//     vueRouter: router, // Pass the router instance to automatically sync with router (optional)
//     // ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
//     trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
// });

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
