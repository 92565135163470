<template>
    <div class="popup mobile-popup" type="Poll" size="small" :status="status">
        <div class="popup-header">
            <h1>Опрос завершен</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <p><strong>Благодарим Вас за{{'\xa0'}}прохождение опроса! Ваше мнение очень важно для нас.</strong>
                    Если у{{'\xa0'}}вас есть комментарии по{{'\xa0'}}опросу, пожелания или предложения, будем очень признательны, если вы{{'\xa0'}}укажете их{{'\xa0'}}ниже.</p>

                <div class="form-item">
                    <textarea id="donecom" v-model.trim="comment" required></textarea>
                    <label for="donecom">Комментарий</label>
                </div>
            </div>

            <div class="mobile-bottom inline" halign="right">
                <div class="button" type="confirm" @click="sendResult()">Отправить</div>
            </div>
        </Component>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

    export default {
        props: ['activePopup', 'contentScroll'],
        components: {
            SmoothScrollbar
        },
        data () {
            return {
                status: '',
                comment: ''
            }
        },
        methods: {
            prependQuestions () {
                let result = []
                for (let question of this.activePopup.questions) {
                    let selectedAnswers = []
                    if (question.questionType == 1) {
                        selectedAnswers.push(question.answer)
                    } else {
                        for (let answer of question.answers) {
                            if (answer.checked) selectedAnswers.push(answer.answerId)
                        }
                    }

                    let item = {
                        questionId: question.questionId,
                        selectedAnswerIds: selectedAnswers
                    }
                    result.push(item)
                }
                return result
            },
            sendResult () {
                this.status = 'loading'
                let data = {
                    pollId: this.activePopup.pollId,
                    comment: this.comment,
                    questions: this.prependQuestions()
                }
                this.axios({url: 'api/v1/polls/answerpoll', data:data, method: 'POST'})
                    .then(() => {
                        this.status = ''
                        this.$emit('closePopup', true)
                    }).catch(() => {
                        this.status = ''
                    })

            }
        }
    }
</script>