<template>
    <div class="popup mobile-popup" type="Managers" size="small">
        <div class="popup-header">
            <h1>Ваши менеджеры</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner" v-if="$store.state.common.managers.list">
                <div class="manager inline mob-col" valign="top" v-for="(item, key) in $store.state.common.managers.list" :key="key">
                    <div class="manager-photo">
                        <img class="real-photo" v-if="item.imageUrl" :src="getPhoto(item.imageUrl)" >
                        <img v-else src="@/assets/images/user.svg" />
                    </div>
                    <div class="manager-info">
                        <h4>{{ item.firstName }} {{ item.lastName }}</h4>
                        <p>{{ getManagerType(item.type) }}</p>
                        <div>
                            <div class="inline mob-col" v-if="item.phone">
                                <p>Рабочий телефон:</p>
                                <span class="mob-phone"><a :href="'tel:'+getPhone(item.phone)" class="link">{{ getPhoneNoAdd(item.phone) }}</a>{{ getPhoneAdd(item.phone) }}</span>
                            </div>
                            <div class="inline mob-col" v-if="item.phoneMobile">
                                <p>Дополнительный телефон:</p>
                                <span class="mob-phone"><a :href="'tel:'+getPhone(item.phoneMobile)" class="link">{{ item.phoneMobile }}</a></span>
                            </div>
                            <div class="inline mob-col" v-if="item.email">
                                <p>Email:</p>
                                <span><a :href="'mailto:'+item.email" class="link">{{ item.email }}</a></span>
                            </div>
                            <div class="inline mob-col">
                                <p></p>
                                <div class="button" type="confirm" @click="sendToManager(item.firstName, item.lastName, item.email, item.employeeGuid)">Написать мне</div>
                            </div>
                        </div>

                        <div class="inline mob-col" v-if="item.workingHours">
                            <p>Режим работы:</p>
                            <div class="column">
                                <span v-for="(mode, mkey) in item.workingHours" :key="mkey">{{ mode.day }}: {{ mode.time }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Component>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'

    export default {
        props: ['contentScroll'],
        components: {
            SmoothScrollbar
        },
        methods: {
            sendToManager (fname, lname, email, guId) {
                this.$emit('openPopup', {
                    type: 'SendToManager',
                    managerName: fname + ' ' + lname,
                    managerEmail: email,
                    managerGuid: guId
                })
            },
            getPhoneNoAdd (phone) {
                if (phone.indexOf(' доп') != -1) phone = phone.split(' доп')[0]
                return phone
            },
            getPhoneAdd (phone) {
                if (phone.indexOf('доп ') != -1) {
                    phone = phone.split('доп ')[1]
                    return ' доп. ' + phone
                } else {
                    return ''
                }

            },
            getPhone (phone) {
                if (phone.indexOf(' доп') != -1) phone = phone.split(' доп')[0]
                return phone.replace(/[{()}]|-|\s/g,"");
            },
            getPhoto (img) {
                return process.env.VUE_APP_ROOT_API + '/api/v1' + img
            },
            getManagerType (type) {
                for (let item of this.$store.state.common.managers.types) {
                    if (item.value == type) return item.title
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_Managers.scss";
</style>