<template>
    <div class="popup mobile-popup" type="searchInvoice" size="big">
        <div class="popup-header">
            <h1>Запросить электронную накладную</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <div class="inline mob-col" valign="bottom" halign="between">
                    <div class="form-item" size="small">
                        <input id="invoice_number" type="text" class="text-input" v-model.trim="invoice" required />
                        <label for="invoice_number">№ накладной</label>
                    </div>

                    <div class="range">
                        <Datepicker :value="date_from" @dateUpdate="dateUpdateFrom"></Datepicker>
                        <span>&mdash;</span>
                        <Datepicker :value="date_to" @dateUpdate="dateUpdateTo"></Datepicker>
                    </div>
                    <div class="button" type="confirm" :class="{disabled: invoices.status == 'loading'}" @click="getInvoicesAndReset()">Найти</div>
                </div>

                <div class="table">
                    <strong class="loading" v-if="invoices.status == 'loading' && !invoices.list.length">Загрузка списка накладных</strong>
                    <div v-else>
                        <div v-if="!invoices.list.length">
                            <strong class="no-items">Накладные не найдены</strong>
                        </div>

                        <div v-else>
                            <Table
                                   ref="dataTable"
                                   @getData="getTableData"
                                   @selectRadio="selectInvoice"
                                   :selectedRadio="selectedInvoice.invoiceNumber"
                                   perPageAlign="top"
                                   :data="invoices"
                                   :schema="[
                                    {id: 'invoiceNumber', name: '№ накладной', radio: true},
                                    {id: 'invoiceDate', name: 'Дата'},
                                    {id: 'invoiceSumWithVat', name: 'Сумма c НДС', align: 'right'}
                               ]">
                            </Table>
                            <div class="column right bottom mobile-bottom">
                                <span class="button free2" :class="{disabled: !selectedInvoice}" type="confirm" @click="requestInvoice()">Запросить накладную</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Component>
    </div>
</template>

<script>
import Datepicker from '@/components/Datepicker.vue'
import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'
import Table from '@/components/Table.vue'

export default {
    props: ['contentScroll'],
    data () {
        return {
            invoice: '',
            date_from: this.moment().subtract(1, 'year'),
            date_to: this.moment(),
            invoices: {
                status: '',
                list: [],
                count: null
            },
            selectedInvoice: false,
            details: {},
        }
    },
    components: {
        SmoothScrollbar,
        Datepicker,
        Table
    },
    computed: {
        clientId () {
            return this.$store.state.common.client.id
        }
    },
    watch: {
        clientId () {
            this.getInvoices()
        }
    },
    methods: {
        getInvoicesAndReset () {
            this.getInvoices()
            if (this.$refs.dataTable) this.$refs.dataTable.resetPaginator()
        },
        selectInvoice (invoice) {
            this.selectedInvoice = invoice
        },
        dateUpdateFrom (date) {
            this.date_from = date
        },
        dateUpdateTo (date) {
            this.date_to = date
        },
        getTableData (e) {
            this.getInvoices(e.paginator, e.sort)
        },
        getInvoices (paginator = false, sort = false) {
            let sorting = sort ? sort : (this.$refs.dataTable && this.$refs.dataTable.sort ? this.$refs.dataTable.sort : false)
            this.invoices.status = 'loading'
            let data = {
                clientId: this.$store.state.common.client.id,
                page: paginator ? paginator.current_page : 1,
                itemsPerPage: paginator ? paginator.per_page.value : 10,
                sortBy: sorting ? sorting.name : null,
                sortDesc: sorting ? (sorting.direction == 'DESC' ? true : false) : null,
                invoiceNumber: this.invoice ? this.invoice : null,
                productName: null,
                manufacturer: null,
                dateFrom: this.date_from,
                dateTo: this.date_to
            }

            this.selectedInvoice = false

            this.axios({url: 'api/v1/invoice/getinvoices', data: data, method: 'POST'})
                .then(resp => {
                    this.invoices.status = ''
                    this.invoices.list = resp.data.data
                    this.invoices.count = resp.data.count
                }).catch(() => {
                this.invoices.status = ''
            })
        },
        requestInvoice () {
            this.invoices.status = 'loading'

            let data = {
                filialId: this.selectedInvoice.filialId,
                orderCode: this.selectedInvoice.orderCode
            }

            this.axios({url: 'api/v1/invoice/requestinvoice', data: data, method: 'POST'})
                .then(resp => {
                    this.invoices.status = ''
                    this.$emit('openPopup', {
                        type: 'RequestSend',
                        message: resp.data.message
                    })
                }).catch(() => {
                    this.invoices.status = ''
                })
        }
    },
    mounted () {
        this.getInvoices()
    }
}
</script>