<template>
    <div class="popup mobile-popup message-form" type="ServiceClaim" size="medium" :status="status">
        <div class="popup-header">
            <h1>Претензии к сервису</h1>
            <div class="close" @click="$emit('closePopup')"></div>
        </div>
        <Component class="popup-content" :is="contentScroll">
            <div class="popup-inner">
                <p>Опишите, пожалуйста, проблему, связанную с{{'\xa0'}}обслуживанием</p>

                <div class="inline mob-col" halign="between" valign="bottom">
                    <div class="column">
                        <div class="form-item no-label" :class="{_error: show_error && firstName == ''}">
                            <input type="text" class="text-input" v-model.trim="firstName" required/>
                            <label>Имя*</label>
                        </div>
                        <div class="form-item no-label" :class="{_error: show_error && lastName == ''}">
                            <input type="text" class="text-input" v-model.trim="lastName" required/>
                            <label>Фамилия*</label>
                        </div>
                        <div class="form-item no-label">
                            <input type="text" class="text-input" v-model.trim="middleName" required/>
                            <label>Отчество</label>
                        </div>
                    </div>
                    <div class="column">
                        <div class="form-item" size="small" :class="{_error: show_error && phone.length < 12}">
                            <input type="text" class="text-input" v-model.trim="phone" v-mask="'+7##########'" required/>
                            <label>Ваш номер телефона*</label>
                        </div>
                        <div class="form-item" :class="{_error: show_error && !emailIsValid(email)}">
                            <input type="text" class="text-input" v-model.trim="email" required/>
                            <label>Ваш email*</label>
                        </div>
                        <div class="form-item">
                            <Select size="normal" :class="{_error: show_error && reasons.value == ''}" :data.sync="reasons"></Select>
                            <label>Причина претензии*</label>
                        </div>
                    </div>
                </div>

                <div class="inline form-item">
                    <textarea v-model.trim="comment" :class="{_error: show_error && comment == ''}" required></textarea>
                    <label>Комментарий*</label>
                </div>
            </div>

            <div class="mobile-bottom inline" halign="right">
                <div class="button" type="confirm" @click="sendClaim()">Отправить</div>
            </div>
        </Component>
    </div>
</template>

<script>
    import SmoothScrollbar  from '@/components/_smoothScrollbar.vue'
    import Select from '@/components/_Select.vue'
    import {mask} from 'vue-the-mask'

    export default {
        props: ['contentScroll'],
        directives: {mask},
        components: {
            SmoothScrollbar,
            Select
        },
        data () {
            return {
                status: '',
                firstName: '',
                lastName: '',
                middleName: '',
                phone: '',
                email: '',
                reasons: {
                    open: false,
                    value: '',
                    text_value: null,
                    list: []
                },
                comment: '',
                show_error: false,
                show_error_tm: false,
                error_msg: ''
            }
        },
        computed: {
            formIsValid () {
                if (this.firstName == '' || this.lastName == '' || this.comment == '' || this.reasons.value == '' || !this.emailIsValid(this.email) || this.phone.length < 12) return false
                return true
            }
        },
        methods: {
            emailIsValid (email) {
                return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
            },
            showError (msg = '') {
                this.error_msg = msg
                this.show_error = true
                if (this.show_error_tm) clearTimeout(this.show_error_tm)
                this.show_error_tm = setTimeout(() => {
                    this.show_error = false
                    this.error_msg = ''
                }, 5000)
            },
            getServiceClaimTypes () {
                this.status = 'loading'
                this.axios({url: 'api/v1/claim/getserviceclaimtypes', method: 'GET'})
                    .then(resp => {
                        this.status = ''
                        this.reasons.list = resp.data
                    }).catch(() => {
                        this.status = ''
                    })
            },
            sendClaim () {
                if (!this.formIsValid) {
                    this.showError()
                    return false
                }
                this.show_error = false
                this.status = 'loading'

                let data = {
                    clientId: this.$store.state.common.client.id,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    middleName: this.middleName,
                    email: this.email,
                    phone: this.phone,
                    type: this.reasons.value,
                    comment: this.comment
                }
                this.axios({url: 'api/v1/claim/sendserviceclaim', data: data, method: 'POST'})
                    .then(() => {
                        this.status = ''
                        this.$emit('openPopup', 'MessageSend')
                    }).catch(err => {
                        if (err.response && err.response.data.Message) {
                            this.showError(err.response.data.Message)
                        }
                        this.status = ''
                    })
            }
        },
        mounted () {
            this.getServiceClaimTypes()
        }
    }
</script>

<style lang="scss">
    @import "../../assets/styles/components/popup_MessageForm";
</style>